<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pb-4">
          <h5 class="modal-title" v-if="changeResult === null">
            {{ object?.template?.enable_advanced_page_paths === 0 ? 'Change page path' : 'Change base path' }}
          </h5>
          <h5 class="modal-title" v-else>Page path changed successfuly</h5>
          <button class="close" v-on:click="close" v-show="remove_close"></button>
        </div>
        <div class="modal-body">
          <div v-if="changeResult === null">
            <p class="text-muted">
              <i class="uil uil-info-circle mr-1" /> Any unsaved changes made before changing the page path
              will be lost.
            </p>
            <dynamic-form
              form-type="edit"
              v-if="object"
              ref="form"
              :formFields="structure.list"
              path="sites/sites"
              :structure="structure"
              :object="object"
              :show-submit="false"
              :on-submit="submit"
              submit-label="Change"
              :closeOnDestroy="false"
            />
            <div class="text-right">
              <button
                class="btn btn-primary"
                :class="{ loading: submitLoading }"
                @click="$refs.form.validateBeforeSubmit()"
              >
                <i class="uil uil-check"></i> Update page path
              </button>
            </div>
          </div>
          <div v-if="changeResult" class="">
            <p>
              Page path was successfully changed to
              <strong> {{ changeResult.path }}</strong
              >. To edit the page, a reload is required.
            </p>
            <div class="mb-3">
              <button class="btn btn-primary mt-2" @click="reloadPage">
                <i class="uil uil-refresh"></i>Reload this page
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PagesService from '@services/PagesService.js'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import PagePathChange from '@models/cms/PagePathChange.js'

export default {
  components: { DynamicForm },
  data() {
    return {
      loading: false,
      submitLoading: false,
      changeResult: null,
      object: {},
      structure: {},
      config: PagePathChange.getConfig(),
      remove_close: true,
    }
  },
  mounted() {
    document.getElementById('modal').appendChild(this.$el)
  },
  methods: {
    show(object) {
      let structure = PagePathChange.getStructure()
      this.structure = structure
      let path = object?.path

      if (object?.template?.enable_advanced_page_paths === 1) {
        path = object?.path?.split('/').slice(-2).join('/')
      }

      this.object = {
        site_id: object.site_id,
        page_id: object.id,
        path: path,
        template: object.template,
      }

      this.$refs.modal.classList.add('show')
    },
    close() {
      if (this.changeResult) {
        return
      }
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.changeResult = null
      this.object = null
    },
    async submit() {
      this.submitLoading = true
      await PagesService.changePagePath(this.object)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.$cookies.set('formIsDirty', false)
            this.showSuccessMessage('Page path changed')
            this.changeResult = response.data.result
            this.remove_close = false
          }
        })
        .catch(() => {
          this.showUnknownErrorMessage
        })
        .finally(() => {
          this.submitLoading = false
        })
    },
    reloadPage() {
      window.location = '/site-pages/' + this.object.page_id
    },
  },
}
</script>
