const state = () => ({
  pageType: null,
  market: null,
  category: null,
  siteId: null,
  sportId: 1, // default to soccer
  showDisabled: false,
  date: null,
  showPastEvents: false,
  selectedSidebarItem: 'Home',
  search: null,
  filterChildren: [],
  recentlyVisited: [],
  structure: [
    {
      name: 'Search',
      permission: null,
      icon: 'uil uil-search',
      children: [],
    },
    {
      name: 'Home',
      permission: null,
      icon: 'uil uil-home-alt',
      recentItems: {
        title: 'Recent Items',
        children: [],
      },
      favorites: {
        title: 'Favorites',
        children: [],
      },
    },
    {
      name: 'Data Management',
      displayName: 'DMS',
      permission: null,
      icon: 'uil uil-folder-open',
      children: [
        {
          name: 'Operators',
          icon: 'uil uil-dice-five',
          to: '/operators',
          permission: 'view.operators/operators',
        },
        {
          name: 'Toplists',
          icon: 'uil uil-list-ul',
          to: '/toplists',
          permission: 'view.toplists/lists',
        },
        {
          name: 'Prefilled Toplists',
          icon: 'uil uil-list-ul',
          to: '/prefilled-toplists',
          permission: 'view.toplists/lists',
        },
        {
          name: 'Tracking links',
          icon: 'uil uil-link',
          to: '/tracking-links',
          permission: 'view.operators/affiliate-links',
        },
        {
          name: 'Games',
          icon: 'uil uil-game-structure',
          to: '/games',
          permission: 'view.games/games',
        },
        {
          name: 'Crypto exchanges',
          icon: 'uil uil-coins',
          to: '/crypto-exchanges',
          permission: 'view.cryptos/exchanges',
        },
        {
          name: 'Crypto wallets',
          icon: 'uil uil-wallet',
          to: '/crypto-wallets',
          permission: 'view.cryptos/wallets',
        },
        {
          name: 'Crypto brokers',
          icon: 'uil uil-money-stack',
          to: '/crypto-brokers',
          permission: 'view.cryptos/brokers',
        },
        {
          name: 'Translations',
          icon: 'uil uil-adjust',
          to: '/translations',
          permission: 'view.translations/translations',
        },
        {
          name: 'Affiliate Sites Settings',
          icon: 'uil uil-share-alt',
          to: '/affiliates',
          permission: 'view.operators/affiliates',
        },
      ],
    },
    {
      name: 'Site Management',
      permission: null,
      displayName: 'CMS',
      icon: 'uil uil-globe',
      children: [
        {
          name: 'Pages',
          icon: 'uil uil-file',
          to: '/site-pages',
          permission: 'view.sites/pages',
        },
        {
          name: 'Menus',
          icon: 'uil uil-bars',
          to: '/site-menus',
          permission: 'view.sites/menus',
        },
        {
          name: 'Banners',
          icon: 'uil uil-images',
          to: '/site-banners',
          permission: 'view.sites/banners',
        },
        {
          name: 'Authors',
          icon: 'uil uil-user-circle',
          to: '/site-authors',
          permission: 'view.sites/authors',
        },
        {
          name: 'User Comments',
          icon: 'uil uil-comments',
          to: '/user-comments',
          permission: 'view.content/comments',
        },
        {
          name: 'Deployments',
          icon: 'uil uil-rocket',
          to: '/site-deployments',
          permission: 'view.sites/deployments',
        },
        {
          subHeader: 'SEO',
          subsection: true,
        },
        {
          name: 'Redirects',
          icon: 'uil uil-code-branch',
          to: '/site-redirects',
          permission: 'view.sites/redirects',
        },
        {
          name: 'Breadcrumbs',
          to: '/breadcrumbs',
          permission: 'view.sites/breadcrumbs',
        },
        {
          name: 'Schema',
          to: '/schema',
          permission: 'view.sites/schema-information',
        },
        {
          subHeader: 'Settings',
          subsection: true,
        },
        {
          name: 'Page Types',
          icon: 'uil uil-web-grid',
          to: '/site-templates',
          permission: 'view.sites/templates',
        },
        {
          name: 'Prefilled modules',
          icon: 'uil uil-gold',
          to: '/site-prefilled-modules',
          permission: 'view.sites/prefilled-modules',
        },
        {
          name: 'Site Settings',
          icon: 'uil uil-globe',
          to: '/site-sites',
          permission: 'view.sites/sites',
        },
        {
          name: 'Sections',
          icon: 'uil uil-browser',
          to: '/site-sections',
          permission: 'view.sites/sections',
        },
        {
          name: 'Market sections',
          icon: 'uil uil-sign-alt',
          to: '/site-markets',
          permission: 'view.sites/site-markets',
        },
      ],
    },
    {
      name: 'Affiliate Management',
      permission: null,
      icon: 'uil uil-game-structure',
      displayName: 'AMS',
      children: [
        {
          name: 'Dashboard',
          icon: 'uil uil-user',
          to: '/koala-dashboard',
          permission: 'view.koala/monitoring',
        },
        {
          name: 'Monitoring',
          icon: 'uil uil-user',
          to: '/koala-monitoring',
          permission: 'view.koala/monitoring',
        },
        {
          name: 'Grouped Monitoring',
          icon: 'uil uil-user',
          to: '/koala-grouped-monitoring',
          permission: 'view.koala/monitoring',
        },
        {
          name: 'Accounts',
          icon: 'uil uil-user-circle',
          to: '/koala-accounts',
          permission: 'view.koala/accounts',
        },
        {
          name: 'Account Reports',
          icon: 'uil uil-user-circle',
          to: '/koala-account-reports',
          permission: 'view.koala/account-reports',
        },
        {
          name: 'Proxies',
          icon: 'uil uil-user-circle',
          to: '/koala-proxies',
          permission: 'view.koala/accounts',
        },
        {
          name: 'Reports',
          icon: 'uil uil-comparison',
          to: '/koala-platform-reports',
          permission: 'view.koala/reports',
        },
        {
          name: 'Platforms',
          icon: 'uil uil-apps',
          to: '/koala-platforms',
          permission: 'view.koala/platforms',
        },
        {
          name: 'Repull',
          icon: 'uil uil-apps',
          to: '/koala-repull',
          permission: 'view.koala/account-jobs',
        },
        {
          name: 'Operators',
          icon: 'uil uil-dice-five',
          to: '/koala-operators',
          permission: 'view.koala/operators',
        },
        {
          name: 'Units',
          icon: 'uil uil-filter',
          to: '/koala-units',
          permission: 'view.koala/business-units',
        },
        {
          name: 'Currencies',
          icon: 'uil uil-coins',
          to: '/koala-currencies',
          permission: 'view.koala/currencies',
        },
        {
          subHeader: 'Campaign Tags',
          subsection: true,
        },
        {
          name: 'Campaigns',
          icon: 'uil uil-user',
          to: '/koala-campaigns',
          permission: 'view.koala/campaigns',
        },
        {
          name: 'Tag Values',
          icon: 'uil uil-tag',
          to: '/tag-values',
          permission: 'view.koala/tag-values',
        },
      ],
    },
    {
      name: 'Sports',
      permission: null,
      icon: 'uil uil-trophy',
      children: [
        {
          name: 'Site events',
          icon: 'uil uil-schedule',
          to: '/sports-site-events',
          permission: 'view.sports/site-events',
        },
        {
          name: 'Site providers',
          icon: 'uil uil-share-alt',
          to: '/sports-site-providers',
          permission: 'view.sports/site-providers',
        },
        {
          name: 'Site tournaments',
          icon: 'uil uil-trophy',
          to: '/sports-site-tournaments',
          permission: 'view.sports/site-tournaments',
        },
        {
          name: 'Site countries',
          icon: 'uil uil-globe',
          to: '/sports-site-countries',
          permission: 'view.sports/site-countries',
        },
        {
          name: 'Site sports',
          icon: 'uil uil-football',
          to: '/sports-site-sports',
          permission: 'view.sports/site-sports',
        },
        {
          subHeader: 'General',
          subsection: true,
        },
        {
          name: 'Events',
          icon: 'uil uil-schedule',
          to: '/sports-events',
          permission: 'view.sports/events',
        },
        {
          name: 'Event Mapper',
          icon: 'uil uil-schedule',
          to: '/sports-event-mapper',
          permission: 'view.sports/event-streams',
        },
        {
          name: 'Providers',
          icon: 'uil uil-share-alt',
          to: '/sports-providers',
          permission: 'view.sports/providers',
        },
        {
          name: 'Tournaments',
          icon: 'uil uil-users-alt',
          to: '/sports-tournaments',
          permission: 'view.sports/tournaments',
        },
        {
          name: 'Teams',
          icon: 'uil uil-users-alt',
          to: '/sports-teams',
          permission: 'view.sports/teams',
        },
      ],
    },
    {
      name: 'Widgets',
      permission: null,
      icon: 'uil uil-apps',
      children: [
        {
          name: 'Operator DMS Widgets',
          icon: 'uil uil-list-ul',
          to: '/site-widgets-toplist',
          permission: 'view.widgets/toplist',
        },
        {
          name: 'Sports Widgets',
          icon: 'uil uil-money-stack',
          to: '/site-widgets-odds',
          permission: 'view.widgets/odds',
        },
        {
          name: 'Widgets Settings',
          icon: 'uil uil-money-stack',
          to: '/widgets-settings',
          permission: 'view.widgets/odds-sub-types',
        },
      ],
    },
    {
      name: 'General Data',
      class: 'setup',
      permission: null,
      icon: 'uil uil-database',
      displayName: 'General',
      children: [
        {
          name: 'Selling Points',
          icon: 'uil uil-tachometer-fast-alt',
          to: '/selling-points',
          permission: 'view.globals/selling-points',
        },
        {
          name: 'Payment Methods',
          icon: 'uil uil-euro',
          to: '/deposit-methods',
          permission: 'view.globals/deposit-methods',
        },
        {
          name: 'Game Providers',
          icon: 'uil uil-gold',
          to: '/providers',
          permission: 'view.globals/software',
        },
        {
          name: 'Operator Licences',
          icon: 'uil uil-exclamation-triangle',
          to: '/licences',
          permission: 'view.operators/licenses',
        },
        {
          name: 'Crypto Licences',
          icon: 'uil uil-exclamation-octagon',
          to: '/crypto-licences',
          permission: 'view.cryptos/licences',
        },
        {
          name: 'Crypto Services',
          icon: 'uil uil-setting',
          to: '/crypto-services',
          permission: 'view.cryptos/services',
        },
        {
          name: 'Support Types',
          icon: 'uil uil-headphones',
          to: '/support-types',
          permission: 'view.globals/support-types',
        },
        {
          name: 'Game Categories',
          icon: 'uil uil-calculator-alt',
          to: '/game-categories',
          permission: 'view.globals/game-categories',
        },
        {
          name: 'Currencies',
          icon: 'uil uil-coins',
          to: '/currencies',
          permission: 'view.globals/currencies',
        },
        {
          name: 'Countries',
          icon: 'uil uil-globe',
          to: '/countries',
          permission: 'view.globals/countries',
        },
        {
          name: 'Critics',
          icon: 'uil uil-info',
          to: '/critics',
          permission: 'view.globals/critics',
        },
        {
          name: 'Languages',
          icon: 'uil uil-map',
          to: '/languages',
          permission: 'view.globals/languages',
        },
        {
          name: 'Markets',
          icon: 'uil uil-sign-alt',
          to: '/markets',
          permission: 'view.globals/markets',
        },
        {
          name: 'Units',
          icon: 'uil uil-filter',
          to: '/units',
          permission: 'view.operators/units',
        },
        {
          name: 'Responsible Gamings',
          icon: 'uil uil-filter',
          to: '/responsible-gamings',
          permission: 'view.operators/responsible-gamings',
        },
        {
          name: 'Gambling Companies',
          icon: 'uil uil-filter',
          to: '/gambling-companies',
          permission: 'view.operators/gambling-companies',
        },
        {
          name: 'Affiliate Programs',
          icon: 'uil uil-filter',
          to: '/affiliate-programs',
          permission: 'view.operators/affiliate-programs',
        },
        {
          name: 'Affiliate Platforms',
          icon: 'uil uil-tachometer-fast-alt',
          to: '/affiliate-platforms',
          permission: 'view.operators/affiliate-platforms',
        },
        {
          name: 'Sport Categories',
          icon: 'uil uil-tachometer-fast-alt',
          to: '/sport-categories',
          permission: 'view.operators/sport-categories',
        },
        {
          name: 'Betting Types',
          icon: 'uil uil-tachometer-fast-alt',
          to: '/betting-types',
          permission: 'view.operators/betting-types',
        },
      ],
    },
    {
      name: 'Reports',
      permission: null,
      icon: 'uil uil-newspaper',
      children: [
        {
          name: 'Links & Bonuses',
          icon: 'uil uil-keyhole-circle',
          to: '/links-bonuses',
          permission: 'view.reports/generate-links-and-bonuses-report',
        },
        {
          name: 'Games Report',
          icon: 'uil uil-keyhole-circle',
          to: '/games-report',
          permission: 'view.reports/games-report', //TODO: change permission
        },
        {
          name: 'Pages',
          icon: 'uil uil-keyhole-circle',
          to: '/pages-report',
          permission: ['view.reports/pages-report', 'view.sites/pages'],
        },
        {
          name: 'Operators Report',
          icon: 'uil uil-keyhole-circle',
          to: '/operators-report',
          permission: 'view.reports/operator-report',
          // permission: 'view.reports/generate-links-and-bonuses-report'
        },
        {
          name: 'Site General Data',
          icon: 'uil uil-keyhole-circle',
          to: '/site-general-data',
          permission: 'view.reports/generate-links-and-bonuses-report',
        },
        {
          name: 'Content Report',
          icon: 'uil uil-keyhole-circle',
          to: '/content-report',
          permission: 'view.reports/content-report',
        },
      ],
    },
    {
      name: 'DevOps',
      permission: null,
      icon: 'uil uil-rocket',
      children: [
        {
          name: 'JSON Tokens',
          icon: 'uil uil-keyhole-circle',
          to: '/site-configurations',
          permission: 'view.sites/configs',
        },
        {
          name: 'Backups',
          icon: 'uil uil-download-alt',
          to: '/devops-backups',
          permission: 'view.devops/backups',
        },
        {
          name: 'Purge Cloudflare',
          icon: 'uil uil-cloud-wind',
          to: '/devops-cloudflare',
          permission: 'view.devops/cloudflare',
        },
        {
          name: 'Sync',
          icon: 'uil uil-sync',
          to: '/devops-sync',
          permission: 'view.devops/brew',
        },
      ],
    },
    {
      name: 'Admin',
      class: 'admin',
      icon: 'uil uil-user',
      permission: null,
      children: [
        {
          name: 'Users',
          icon: 'uil uil-users-alt',
          to: '/users',
          permission: 'view.backoffice/users',
        },
        {
          name: 'Roles',
          icon: 'uil uil-user-check',
          to: '/roles',
          permission: 'view.backoffice/roles',
        },
      ],
    },
  ],
})

const getters = {
  category: (state) => {
    return state.category
  },
  pageType: (state) => {
    return state.pageType
  },
  market: (state) => {
    return state.market
  },
  siteId: (state) => {
    return state.siteId
  },
  sportId: (state) => {
    return state.sportId
  },
  showDisabled: (state) => {
    return state.showDisabled
  },
  date: (state) => {
    return state.date
  },
  showPastEvents: (state) => {
    return state.showPastEvents
  },
  bestMatch: (state) => {
    let bestMatch = null
    if (state.search && state.search !== '') {
      let struct = state.structure.slice(2)
      struct.some((category) => {
        bestMatch = category.children.find((child) =>
          child.name?.toLowerCase().includes(state.search.toLowerCase())
        )
        if (bestMatch) {
          return true
        }
      })
    }
    return bestMatch
  },
  filteredChildren() {
    if (!state.selectedSidebarItem) {
      return []
    }
    if (state.search && state.search !== '') {
      // remove Home and Filter Menu from the structure
      let struct = state.structure.slice(2)

      let foundItems = struct.map((n) => {
        return {
          name: n.name,
          children: n.children.filter((child) =>
            child.name?.toLowerCase().includes(state.search?.toLowerCase())
          ),
        }
      })
      foundItems = foundItems.filter((n) => n.children.length > 0)
      return foundItems.flat(1)
    }
    return state.structure.find((n) => n.name === state.selectedSidebarItem).children
  },
  favorites() {
    return state.structure.find((n) => n.name === 'Home').favorites.children
  },
}

const actions = {
  setCategory({ commit }, value) {
    commit('setCategory', value)
  },
  setPageType({ commit }, value) {
    commit('setPageType', value)
  },
  setMarket({ commit }, value) {
    commit('setMarket', value)
  },
  setSite({ commit }, value) {
    commit('setSite', value)
  },
  setSport({ commit }, value) {
    commit('setSport', value)
  },
  setShowDisabled({ commit }, value) {
    commit('setShowDisabled', value)
  },
  setDate({ commit }, value) {
    commit('setDate', value)
  },
  setShowPastEvents({ commit }, value) {
    commit('setShowPastEvents', value)
  },
  getFavorites({ commit }) {
    let favorites = JSON.parse(localStorage.getItem('favorites'))
    if (favorites?.length) {
      commit('setFavoriteItem', favorites)
    }
  },
}

const mutations = {
  setSelectedSidebarItem(state, value) {
    state.selectedSidebarItem = value
    state.search = ''
  },

  setCategory(state, value) {
    state.category = value
  },
  setPageType(state, value) {
    state.pageType = value
  },
  setMarket(state, value) {
    state.market = value
  },
  setSite(state, value) {
    state.siteId = value
  },
  setSport(state, value) {
    state.sportId = value
  },
  setShowDisabled(state, value) {
    state.showDisabled = value
  },
  setDate(state, value) {
    state.date = value
  },
  setShowPastEvents(state, value) {
    state.showPastEvents = value
  },
  setSearch(state, value) {
    state.search = value
  },

  setFavoriteItem(state, value) {
    const home = state.structure.find((n) => n.name === 'Home').favorites

    // if we already have Favorites array in the Local Storage, set them here and don't proceed any further
    if (value.length) {
      home.children = value
      return
    }
    // Proceed with setting the favorite item in the state array (state.structure[1] which is 'Home') and local storage
    const foundIndex = home.children.findIndex((n) => n.to === value.to)

    if (foundIndex !== -1) {
      home.children.splice(foundIndex, 1)
    } else {
      home.children.push(value)
    }
    // set favorites to local storage
    localStorage.setItem('favorites', JSON.stringify(home.children))
  },
  setRecentlyVisited(state, value) {
    const home = state.structure.find((n) => n.name === 'Home').recentItems
    // Find the parent and child of the recently visited item
    const parent = state.structure.find((n) => n.name === value.parent)

    let child = null
    // /profile and /settings are not items of the sidebar
    if (value.to === '/profile') {
      child = {
        name: 'Profile',
        to: '/profile',
        permission: null,
      }
    } else if (value.to === '/settings') {
      child = {
        name: 'Settings',
        to: '/settings',
        permission: null,
      }
    } else if (value.to === '/site-logs') {
      child = {
        name: 'Site Logs',
        to: '/site-logs',
        permission: 'view.site-logs',
      }
    } else {
      child = parent?.children?.find((n) => n.to === value.to)
    }
    if (!child) {
      return
    }
    // Check if there already is that element inside structure > Home > children
    const foundIndex = home.children.findIndex((n) => n.to === value.to)
    if (foundIndex !== -1) {
      home.children.splice(foundIndex, 1)
    }
    // remove the last element from the array and add the new one
    home.children = [child, ...home.children.slice(0, 2)]
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
