<template>
  <div>
    <data-table
      model="koala/Unit"
      service-file="koala/UnitsService"
      :path="path()"
      permissionPath="koala/v1/business-units"
      :columns="columns()"
      :columnTemplates="columnTemplates()"
      ref="table"
      :rowActionButtons="rowActions()"
      :tableLimits="[10, 50, 100]"
      :showRemove="false"
    />
  </div>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'

export default {
  created() {
    this.setTitle()
  },
  components: {},
  methods: {
    columns() {
      return [
        {
          field: 'name',
          type: 'text',
          search_key: 'name',
          filterable: true,
          advancedFilter: true,
          showModalAdvanced: true,
          basicFilterType: 'text',
          showEmptyNull: false,
        },
        { field: 'updated', type: 'text', filterable: false, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    columnTemplates() {
      return Object.assign({
        name: function (row) {
          return row.name
        },
      })
    },
    rowActions() {
      return [
        {
          action: this.monitoringAction,
          title: 'Monitoring',
          icon: 'uil uil-align-justify',
          class: 'btn-warning',
        },
      ]
    },
    monitoringAction(row) {
      let routeData = this.$router.resolve({
        path: `/koala-monitoring?`,
        query: {
          business_units: row.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    path() {
      return `koala/v1/business-units`
    },
  },
}
</script>
