<template>
  <div v-bind:class="containerClass">
    <div class="pt-1 asset-details p-3">
      <div class="asset-detail-container" v-bind:class="loading && 'loading'">
        <div v-if="!asset">
          <p class="text-muted font-size-sm mt-2">Select an image for more detail or upload a new one</p>
        </div>
        <div v-if="asset">
          <div class="w-100 d-flex justify-content-center thumbnail mt-2 mb-2">
            <i class="uil uil-link"></i>
            <a :href="asset.url" target="blank">
              <base-asset :asset="asset" width="auto" />
            </a>
          </div>
          <div class="w-100 selected-img-info d-flex justify-content-between mb-1 mt-3">
            <p class="mb-0 text-muted"><strong>ID: </strong>{{ asset.id }}</p>
            <p v-if="Number(asset.watermarked)" class="mb-0 badge badge-pill badge-info">Watermark</p>
          </div>
          <div class="w-100 selected-img-info d-flex justify-content-between mb-1">
            <p class="mb-0 text-muted"><strong>Width: </strong>{{ asset.width }}px</p>
            <p class="mb-0 text-muted"><strong>Height: </strong>{{ asset.height }}px</p>
          </div>
          <div class="w-100 selected-img-info d-flex justify-content-between mb-1">
            <p class="mb-0 text-muted"><strong>Extension: </strong>{{ asset.extension.replace('.', '') }}</p>
            <p class="mb-0 text-muted"><strong>Size: </strong>{{ sizeConverter(this.asset.size) }}</p>
          </div>
          <div class="w-100 selected-img-info mb-1">
            <p class="mb-0 text-muted"><strong>File name: </strong>{{ asset.raw_filename }}</p>
          </div>
          <div v-if="asset.site" class="w-100 selected-img-info mb-1">
            <p class="mb-0 text-muted mb-1"><strong>Site: </strong>{{ asset.site.name }}</p>
            <p class="mb-0 text-muted" v-if="asset.market">
              <strong>Market: </strong>{{ asset.market.label }}
            </p>
          </div>
          <hr />
          <base-input
            v-bind="tooltipProps('title')"
            :readOnly="isGeneralImage || isStandardised"
            v-model="modifiedAsset.title"
            label="Title"
            class="title-img"
          />
          <base-input
            v-bind="tooltipProps('alt')"
            :readOnly="isGeneralImage || isStandardised"
            label="Alt Text"
            class="alt"
            v-model="modifiedAsset.alt"
          />
          <label class="form-label">Color picker</label>
          <input
            v-bind="tooltipProps('color')"
            type="color"
            v-model="modifiedAsset.color"
            class="form-control"
            style="padding: 4px 6px"
            :disabled="isGeneralImage || isStandardised"
          />
          <div class="d-flex justify-content-center mt-2">
            <button @click="insertAsset" class="btn btn-primary mb-3 col-12">
              <i class="uil uil-check"></i> {{ buttonText }}
            </button>
          </div>

          <div class="d-flex justify-content-center mt-2">
            <button
              @click="openCloneModal"
              class="btn btn-outline-info col-12"
              :disabled="activeTab === 'standardised'"
            >
              <i class="uil uil-copy"></i>
              {{ buttonCloneText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseTag from '@atoms/fields/base-tag.vue'
import BaseAsset from '@atoms/fields/base-asset.vue'
import BaseSelect from '@/components/fields/base-select.vue'

export default {
  components: { BaseInput, BaseTag, BaseAsset, BaseSelect },
  data() {
    return {
      tags: null,
      loading: false,
      modifiedAsset: {},
      image: null,
    }
  },
  watch: {
    asset: function () {
      if (this.asset) {
        this.modifiedAsset = Object.assign({}, this.asset)
      }
    },
  },
  computed: {
    isGeneralImage() {
      return this.asset?.site_id === null
    },
    isStandardised() {
      return this.asset?.standardised === '1'
    },
    siteSpecific() {
      return this.$store.state.media.siteSpecific
    },
  },
  methods: {
    openCloneModal() {
      this.$emit('clone', this.modifiedAsset)
    },
    insertAsset() {
      this.$emit('insert', this.modifiedAsset)
    },
    tooltipProps(name) {
      if (this.siteSpecific && this.isGeneralImage) {
        return { 'data-tooltip': `Make a site specific clone of the image to modify the ${name}` }
      }
      return {}
    },

    copiedUrl() {
      this.showSuccessMessage('Copied to your clipboard.')
    },
  },
  props: {
    buttonText: {
      type: String,
      default: () => 'Add asset',
    },
    activeTab: {
      type: String,
      default: () => '',
    },
    buttonCloneText: {
      type: String,
      default: () => 'Clone selected image',
    },
    asset: {
      type: Object,
    },
    containerClass: {
      type: String,
    },
  },
}
</script>

<style scoped>
.thumbnail {
  position: relative;
}

.thumbnail:hover img {
  opacity: 0.2;
}

.thumbnail i {
  display: none;
}

.thumbnail:hover i {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.selected-img-info {
  font-size: 12px;
}

.asset-details {
  background: #f9fafa;
  border-radius: 5px;
  border: 1px solid #cfd8dc;
}

.asset-detail-container.loading:before {
  position: absolute;
  top: 45%;
  left: 45%;
  border-color: transparent transparent #2196f3 #2196f3;
}

.loading {
  opacity: 0.5;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-label {
  color: #39394F
}
</style>
