import LinkBonuses from '@pages/reports/links-bonuses.vue'
import GamesReport from '@pages/reports/games-report.vue'
import PagesReport from '@pages/reports/pages-report.vue'
import Operators from '@pages/reports/operators.vue'
import SiteGeneralData from '@pages/reports/site-general-data.vue'
import ContentReport from '@pages/reports/content-report.vue'

export default [
  {
    path: '/links-bonuses',
    component: LinkBonuses,
    name: 'Links & Bonuses report',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Links & Bonuses report',
      permission: 'view.reports/generate-links-and-bonuses-report',
    },
  },
  {
    path: '/games-report',
    component: GamesReport,
    name: 'Games report',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Games report',
      permission: 'view.reports/games-report',
    },
  },
  {
    path: '/pages-report',
    component: PagesReport,
    name: 'Pages report',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Pages report',
      permission: ['view.reports/pages-report', 'view.sites/pages'],
    },
  },
  {
    path: '/operators-report',
    component: Operators,
    name: 'Operators report',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Operators report',
      permission: 'view.reports/operator-report',
    },
  },
  {
    path: '/site-general-data',
    component: SiteGeneralData,
    name: 'Site General Data',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Site General Data',
      permission: 'view.reports/generate-links-and-bonuses-report',
    },
  },
  {
    path: '/content-report',
    component: ContentReport,
    name: 'Content Report',
    meta: {
      parent: 'Reports',
      auth: true,
      name: 'Content Report',
      permission: 'view.reports/content-report',
    },
  },
]
