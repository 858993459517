<template>
  <div class="d-flex pt-0 pb-0 pl-2 filter-container position-relative">
    <base-select
      v-if="column.basicFilterType === 'select'"
      v-bind:class="[!(column.showModalAdvanced || column.clearFilter) ? 'mr-2 col mb-0' : 'col-10 mb-0']"
      :style="column.width ? 'min-width:' + column.width : 'min-width: 150px'"
      :wrapperClass="null"
      :placeholder="$prettyLabels(placeholder)"
      v-bind:custom-options="column.customOptions"
      v-bind:path="column.path"
      v-model="basicFilterValue"
      v-bind:optionLabelKey="column.optionLabelKey"
      :disabled="!basicFilterEnabled"
      :loadOnMount="typeof column.fetch_on_load !== 'undefined' ? column.fetch_on_load : true"
      :trackBy="column.trackBy"
      :clearable="column.clearable"
      :showAllOption="column.showAllOption"
      :allowNullOption="true"
      :searchBy="column.searchBy"
      :removeActiveText="true"
      :extraOptionLabelKey="column.extraSelectLabel"
      @update:modelValue="clearPaginationOnInput"
      @input="clearPaginationOnInput"
      :enableDynamicHeight="!tableIsModal"
      :enableTooltip="column.enableTooltip"
      :advancedFilterParams="column.advancedFilterParams"
    />

    <base-date
      v-else-if="column.basicFilterType === 'date'"
      v-model="basicFilterValue"
      :clearable="column.clearable"
      :wrapperClass="column.wrapperClass"
      ref="dateSelect"
      :config="datePickerConfig()"
      option-label-key="name"
      :style="column.width ? 'min-width:' + column.width : 'min-width: 150px'"
    />

    <VueDatePicker
      v-else-if="column.basicFilterType === 'vue-date-picker'"
      v-model="basicFilterValue"
      range
      cancel-text="Close"
      input-class-name="dp-custom-input"
      position="left"
      :disabled-dates="disabledAfterToday"
    />

    <base-input
      v-else
      type="text"
      v-bind:class="[
        !(column.showModalAdvanced || column.clearFilter) ? 'mr-2 ml-0 col mb-0' : 'ml-0 col mb-0',
      ]"
      :style="column.width ? 'width:' + column.width : ''"
      :placeholder="placeholder"
      :showLabel="false"
      v-model="basicFilterValue"
      :disabled="!basicFilterEnabled"
      @update:modelValue="clearPaginationOnInput"
      @input="clearPaginationOnInput"
      style="min-width: 100px"
    />

    <div class="ml-1 my-auto" v-if="column.showModalAdvanced || column.clearFilter">
      <i class="uil uil-filter d-flex align-items-center text-muted" @click="showModal"></i>

      <span
        class="badge icon-badge remove-filter"
        @click="clearFilter"
        data-tooltip="Clear Filters"
        v-if="!basicFilterEnabled || basicFilterValue"
      >
        <i class="uil uil-times"></i>
      </span>
    </div>

    <div class="modal" ref="modal" v-click-outside="closeModal" v-if="column.showModalAdvanced">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Advanced Filter</h5>

            <button class="close" v-on:click="closeModal"></button>
          </div>

          <div class="modal-body pb-0">
            <base-radio
              label="Filter By: "
              name="filter"
              :modelValue="include && 'include'"
              :data="extraFilters"
              @update:modelValue="
                (option) => (
                  (include = option.value === 'include'),
                  (showOnlyEmpty = option.value === 'only_empty'),
                  (showOnlyPopulated = option.value === 'only_populated')
                )
              "
              :disabled="selectEmpty"
            />

            <base-multiselect
              v-if="column.path || column.customOptions"
              :label="column.fieldCustomName ? column.fieldCustomName : $prettyLabels(column.field)"
              :placeholder="column.optionPlaceholder"
              v-bind:custom-options="column.customOptions"
              v-bind:path="column.path"
              v-bind:selectLabel="column.optionLabelKey"
              :extraOptionLabelKey="column.extraSelectLabel"
              :field="column.field"
              :multiple="true"
              @sync="saveFilterValue"
              :trackByOption="column.trackBy"
              :searchBy="column.searchBy"
              :disabled="selectEmpty || showOnlyEmpty || showOnlyPopulated"
              :loadOnMount="typeof column.fetch_on_load !== 'undefined' ? column.fetch_on_load : true"
              helpText="Filter will identify entries with any of the above (OR logic)."
              ref="multiselect"
            />

            <div class="form-group" v-else-if="column.textarea">
              <textarea
                v-model="filterValue"
                :label="$prettyLabels(column.field)"
                :placeholder="column.optionPlaceholder"
                class="form-control mb-3"
                ref="textarea"
                type="text"
                rows="5"
                :disabled="selectEmpty || showOnlyEmpty || showOnlyPopulated"
              ></textarea>

              <small class="form-text text-muted">
                Text will only clear when the filter is cleared. You may close and reopen this filter modal to
                paste more text as needed.
              </small>
            </div>

            <base-input
              v-else
              type="text"
              v-model="filterValue"
              :label="$prettyLabels(column.label ? column.label : column.field)"
              :placeholder="placeholder"
              :disabled="selectEmpty || showOnlyEmpty || showOnlyPopulated"
              helpText="Filter will identify entries with any of the above (OR logic)."
            />

            <hr v-if="column.showEmptyNull" />

            <base-checkbox
              v-if="column.showEmptyNull"
              v-model="selectEmpty"
              label="Show only empty/null values"
            />
          </div>

          <div class="modal-footer">
            <button class="btn btn-secondary mr-2" v-on:click="closeModal">Close</button>

            <button class="btn btn-primary" v-on:click="applyFilter(false)">Apply</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import _ from 'lodash'
import moment from 'moment'

export default {
  components: { BaseSelect, BaseDate, BaseMultiselect, BaseInput, BaseRadio, BaseCheckbox },
  watch: {
    basicFilterValue(filterValue) {
      if (this.suppressFilterChange) {
        return
      }
      if (Array.isArray(filterValue) && filterValue.length === 2) {
        this.processDateRange(filterValue)
      } else {
        this.checkBasicFilterValue(filterValue)
      }
    },
    filtersEnabled() {
      let searchKey = this.column.search_basic_key ? this.column.search_basic_key : this.column.search_key
      const fieldKey = searchKey || this.column.field
      const filterValue = this.filtersEnabled[fieldKey] || ''

      if (this.column.basicFilterType === 'vue-date-picker' && filterValue) {
        this.processVueDatePickerFilter(filterValue)
      } else {
        this.suppressFilterChange = true
        this.basicFilterValue = filterValue
        this.$nextTick(() => {
          this.suppressFilterChange = false
        })
      }
    },
  },
  created() {
    if (this.column.extraFilters) {
      this.extraFilters = this.extraFilters.concat(this.column.extraFilters)
    }
    this.tableIsModal = this.$store.hasModule('model-select-items-selector')
  },
  computed: {
    placeholder() {
      if (this.basicFilterEnabled) {
        return `Filter`
      }
      return 'Advanced Filter On'
    },
  },
  data() {
    return {
      tableIsModal: false,
      filterValue: '',
      include: true,
      showOnlyEmpty: false,
      showOnlyPopulated: false,
      basicFilterValue: '',
      basicFilterEnabled: true,
      selectEmpty: false,
      extraFilters: ['include', 'exclude'],
      suppressFilterChange: false,
    }
  },
  props: {
    column: {
      required: true,
      type: Object,
    },
    showModalAdvanced: {
      type: Boolean,
      default: () => false,
    },
    filtersEnabled: {
      type: Object,
      default: () => {},
    },
    stateName: {
      type: String,
      default: 'data-table',
    },
    isKoalaModel: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    processVueDatePickerFilter(filterValue) {
      const dateStrings = filterValue.split(' to ')
      const dateValues = dateStrings.map((dateStr) => new Date(dateStr))
      this.suppressFilterChange = true
      this.basicFilterValue = dateValues
      this.$nextTick(() => {
        this.suppressFilterChange = false
      })
    },
    processDateRange(filterValue) {
      const formattedRange = filterValue.map((date) => moment(date).format('YYYY-MM-DD HH:mm'))
      this.checkBasicFilterValue(formattedRange.join(' to '))
    },
    checkBasicFilterValue: _.debounce(function (filterValue) {
      this.$emit('filterChange', { [this.column.field]: filterValue })
    }, 300),

    filterName(include) {
      let customPath
      if (this.column.includeCustomPath && this.column.model === 'AffiliateLink') {
        customPath = include
          ? 'is_in[' + this.column.search_key + ']'
          : 'is_not[' + this.column.search_key + ']'
      }

      if (this.column.includeCustomPath) {
        customPath = include
          ? 'include[' + this.column.search_key + ']'
          : 'exclude[' + this.column.search_key + ']'
        if (this.selectEmpty) {
          customPath = 'is_null_or_empty[]'
          return customPath
        }
        return customPath
      }
      return include ? '' : this.isKoalaModel ? '_exclude' : '__exclude'
    },
    clearFilter() {
      this.basicFilterValue = ''
      if (this.basicFilterEnabled) {
        this.filterValue = ''
        this.basicFilterEnabled = true
      } else {
        this.filterValue = ''
        this.selectEmpty = false
        this.showOnlyEmpty = false
        this.showOnlyPopulated = false
        this.applyFilter(true)
        this.include = true
        this.$refs.multiselect && this.$refs.multiselect.deselectAll()
        this.basicFilterEnabled = true
      }
    },
    saveFilterValue(field, value) {
      if (
        this.$route.path === '/tracking-links' &&
        this.column.customOptions &&
        this.column.label === 'Status'
      ) {
        this.column.customOptions = [
          { label: 'Active', id: 'active' },
          { label: 'Inactive', id: 'inactive' },
          { label: 'Restricted', id: 'restricted' },
          { label: 'Coming soon', id: 'coming_soon' },
          { label: 'Blacklisted', id: 'blacklisted' },
          { label: 'Not recommended', id: 'not_recommended' },
        ]
      }

      this.filterValue = _.map(value, (item) => item[this.column.trackBy ? this.column.trackBy : 'id']).join()
    },
    applyFilter(reset) {
      this.basicFilterValue = ''

      let nullOrEmptyValue
      if (
        this.column.model === 'AffiliateLink' &&
        (this.column.field === 'link' || this.column.field === 'custom_link')
      ) {
        nullOrEmptyValue = this.column.field
      } else {
        nullOrEmptyValue = true
      }

      let showValues = this.selectEmpty || this.showOnlyEmpty
      let filterObj = {
        [this.getEliasEmptyFieldName(this.column)]: showValues ? nullOrEmptyValue : '',
        [`is_not_empty[${this.column.field}]=`]: this.showOnlyPopulated ? nullOrEmptyValue : '',
      }

      reset = this.selectEmpty || this.showOnlyEmpty || this.showOnlyPopulated ? true : reset
      let columnField = this.isKoalaModel
        ? this.column.search_key
        : !this.column.includeCustomPath
        ? this.column.field
        : ''
      Object.assign(filterObj, {
        [`${columnField}${this.filterName(true)}`]: this.include && !reset ? this.filterValue : '',
        [`${columnField}${this.filterName(false)}`]: !this.include && !reset ? this.filterValue : '',
      })
      this.basicFilterEnabled = false
      this.$emit('filterChange', filterObj)
      this.closeModal()
    },
    showModal() {
      this.basicFilterValue = ''
      document.getElementById('modal').appendChild(this.$refs.modal)
      setTimeout(() => {
        this.$refs.modal.classList.add('show')
      }, 100)
    },
    closeModal() {
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    clearPaginationOnInput() {
      // moved this from table component to here bcs of pagination memory
      this.$store.dispatch(`${this.stateName}/setActivePage`, 1)
    },
    datePickerConfig() {
      let configs = {
        date: null,
        altInput: true,
        altFormat: 'Y-m-d',
        dateFormat: 'Y-m-d',
        time_24hr: true,
        enableTime: false,
      }

      if (typeof this.column.dateOptions !== 'undefined') {
        this.column.dateOptions.includes('only_future_dates') ? (configs.minDate = moment().format()) : null
        this.column.dateOptions.includes('range') ? Object.assign(configs, { mode: 'range' }) : null
        this.column.dateOptions.includes('only_past_dates') ? (configs.maxDate = moment().format()) : null
        if (this.column.dateOptions.includes('with_time')) {
          configs.enableTime = true
          configs.altFormat = 'Y-m-d H:i'
          configs.dateFormat = 'Y-m-d H:i'
        }
      }

      return configs
    },
    disabledAfterToday(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date > today
    },
  },
  unmounted() {
    this.$store.commit(`${this.stateName}/setAdvancedFilters`, {})
  },
}
</script>

<style scoped>
.filter-container > div,
.filter-container > i {
  padding: 0;
}

i {
  cursor: pointer;
}

.badge.icon-badge.remove-filter {
  position: absolute;
  display: block;
  right: -10px;
  top: 15%;
  transform: translate(-50%, -50%);
  width: 15px;
  height: 15px;
  font-size: 0px;
  z-index: 1;
}

.remove-filter i {
  font-size: 16px !important;
}
</style>
