<template>
    <div class="row d-flex justify-content-center">
      <div class="card col-12 col-xl-6">
        <div class="card-header">
          <h3 class="card-title">Content Report</h3>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-10">
              <base-select
                label="Site"
                option-label-key="name"
                path="hercules/sites/sites?orderBy=name&ascending=1"
                v-model="site"
                :required="true"
                trackBy="id"
              />
            </div>
          </div>
          <div class="row">
            <base-select
              v-model="market"
              field="market"
              :loadOnMount="false"
              label="Market"
              selectLabel="label"
              trackByOption="id"
              :required="true"
              :path="marketsPath"
              class="col-10 pr-2 pl-2 mb-0"
            />
          </div>
          <div class="row">
            <base-select
                v-model="pageType"
                field="pageType"
                :loadOnMount="false"
                label="Page Type"
                option-label-key="name"
                trackByOption="id"
                :required="true"
                :path="`hercules/sites/templates?site_id=${site}&orderBy=name&ascending=1&market_id=${market}&fields_only[]=id,&fields_only[]=name`"
                class="col-10 pr-2 pl-2 mb-0"
                @sync="syncTemplate"
            />
          </div>
        </div>
        <div class="card-footer">
          <button :class="{ loading: loading }" class="btn btn-primary icon-btn" @click="exportCsv">
            <i class="uil uil-file-export"></i>Export to CSV
          </button>
        </div>
      </div>
    </div>
  </template>
<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import moment from 'moment'
import { ExportToCsv } from 'export-to-csv'

const { proxy} = getCurrentInstance()
const site = ref(null)
const market = ref(null)
const pageType = ref(null)
const loading = ref(false)

const marketsPath = computed(() => {
  return site.value
    ? `hercules/sites/markets/by-site/${site.value}?fields_only[]=id,&fields_only[]=label`
    : ''
})

const pageTypePath = computed(() => {
  return site.value && market.value
    ? `hercules/sites/templates?site_id=${site.value}&orderBy=name&ascending=1&market_id=${market.value}&fields_only[]=id,&fields_only[]=name`
    : ''
})

function syncTemplate(_field, value, key) {
  pageType.value = value && Array.isArray(value) ? value.map((item) => item[key]) : null
}

const exportCsv = async () => {
  console.log('Exporting CSV')
  // loading.value = true
  const filename = `content-report-${moment().format('YYYY-MM-DD-HHmm')}`

  const params = {
    site: site.value,
    market: market.value,
    pageType: pageType.value
  }

  await proxy.$http.get('hercules/sites/content-type-report', { params })
  .then((response) => {
    console.log(response)
    if (response.data.messages) {
      proxy.showErrorMessages(response.data.messages)
    } else {
      const items = response.data.result
      if (items.length === 0) {
        proxy.showErrorMessages('No results for selected fields')
      } else {
        const csvExporter = new ExportToCsv({
          useKeysAsHeaders: true,
          filename
        })
        csvExporter.generateCsv(items)
      }
    }
  })
  .catch(() => {
    proxy.showUnknownErrorMessage()
  })
  .finally(() => {
    loading.value = false
  })
} 
</script>
  