<template>
  <div class="modal" ref="modal" tabindex="-1" role="dialog" v-click-outside="close">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Bonus Type to {{ row.operator.name }}</h5>
          <button class="close" v-on:click="close"></button>
        </div>
        <div class="modal-body pb-2">
          <base-select
            :customOptions="affiliateOperatorItems"
            label="Bonus Type"
            selectLabel="name"
            optionLabelKey="name"
            v-model="bonusType"
            :loadOnMount="true"
            :clearable="true"
          />
        </div>
        <div class="modal-footer pt-3">
          <div class="d-flex justify-content-end align-items-end w-100">
            <div class="w-25 d-flex justify-content-end text-right">
              <button type="submit" class="btn btn-primary" @click="submitModal">
                <i class="uil uil-check"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import GeneralService from '@/services/GeneralService'

export default {
  components: { BaseCheckbox, BaseSelect },
  data() {
    return {
      showModal: false,
      bonusType: '',
      affiliateOperatorItems: [],
    }
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    row: {
      type: Object,
      required: true,
    },
    list: {
      type: Object,
      required: true,
    },
    storeName: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    document.getElementById('modal').appendChild(this.$el)
    await this.getAffiliateOperators()
    this.bonusType = this.affiliateOperatorItems.find((type) => type.name == this.row.bonus_name) || ''
  },
  computed: {
    affiliateOperatorId() {
      return this.list?.items[this.index]?.operator?.affiliate_operators[0]?.id
    },
  },
  methods: {
    show() {
      this.$refs.modal.classList.add('show')
    },
    close() {
      let clone = cloneDeep(this.row)
      this.$store.dispatch('toplist/updateItem', clone)
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
    async getAffiliateOperators() {
      const path = `hercules/operators/affiliate-operators/${this.affiliateOperatorId}/types`
      await GeneralService.fetchItems(path, {})
        .then((response) => {
          return (this.affiliateOperatorItems = response.data.result)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async submitModal() {
      this.$nextTick(() => {
        const { id, name } = this.affiliateOperatorItems.find((type) => type.id == this.bonusType)
        this.$emit('updateBonusType', { id, name })
      })
      this.$refs.modal && this.$refs.modal.classList.remove('show')
    },
  },
}
</script>
