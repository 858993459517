import Api from './Api'

export default {
  getAll() {
    return Api().get('backoffice/users')
  },
  getOne(id) {
    return Api().get(`backoffice/users/${id}`)
  },
  update(data) {
    return Api().put('backoffice/users', data)
  },
  updateSettings(data) {
    return Api().put('backoffice/user-settings', data)
  },
  post(data) {
    return Api().post('backoffice/users', data)
  },
  remove(data) {
    return Api().delete(`backoffice/users/${data.id}`)
  },
  getRoles(id) {
    return Api().get(`backoffice/users/${id}/roles`)
  },
  updateRoles(id, roles) {
    return Api().put(`backoffice/users/${id}/roles`, { roles: roles })
  },
  getPermissions(id) {
    return Api().get(`backoffice/users/${id}/permissions`)
  },
  impersonate(row) {
    return Api().post(`backoffice/users/impersonate/${row.id}`)
  },
  removeImpersonation() {
    return Api().delete(`backoffice/users/impersonate`)
  },
  getSlackInfoBasedOnEmail(email) {
    return Api().get(`backoffice/users/slack/${email}`)
  },
  getUsersByRole(data) {
    return Api().get('backoffice/users/byRole',{ params: data })
  }
}
