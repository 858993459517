<template>
  <div class="card">
    <div class="card-body">
      <div class="d-flex pb-2 justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <h2 class="mb-0">Tracking links edit</h2>
          <a
            class="btn btn-info btn-md rounded ml-4"
            v-bind:class="{ loading: loading }"
            @click="openNewLinkModal"
          >
            <i class="uil uil-plus mr-2"></i> Add new link
          </a>
        </div>
        <div class="d-flex align-items-center">
          <button class="btn btn-primary ml-4" v-bind:class="{ loading: loading }" @click="submit()">
            <i class="uil uil-check mr-2"></i> Save changes
          </button>
        </div>
      </div>
      <div class="mt-2">
        <div
          v-for="(row, index) in rows"
          :key="index"
          class="link-row"
          :class="[row.delete ? 'is-invalid' : 'row-background']"
        >
          <div class="row-header">
            <span class="link-number text-muted"># {{ index + 1 }}</span>
            <div class="row-actions">
              <span
                :class="['badge d-flex justify-content-center align-items-center', linkType(row, true)]"
                >{{ linkType(row) }}</span
              >
              <div>
                <button
                  class="btn btn-link icon-button"
                  :class="[row.delete && 'text-red']"
                  @click="deleteLink(row)"
                >
                  <i class="uil uil-trash"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="row-info">
            <div class="type">
              <div class="mb-2 header-color">TYPE</div>
              <div class="text-muted text-capitalize d-flex justify-content-center align-items-center h-50">
                {{ row.name }}
              </div>
            </div>
            <div class="operator">
              <div class="mb-2 header-color">OPERATOR/TYPE</div>
              <div>{{ row.affiliate_operator.operator.name }}</div>
              <div class="text-muted text-capitalize">{{ row.affiliate_operator.type }}</div>
            </div>
            <div class="market">
              <div class="mb-2 header-color">MARKET/SITE</div>
              <div>{{ row.affiliate_operator.market.label }}</div>
              <div class="text-muted">{{ row.affiliate_operator.affiliate.name }}</div>
            </div>
            <div class="updated">
              <div class="mb-2 header-color">UPDATED</div>
              <div>{{ $dateRelative(row.updated_at) }}</div>
            </div>
          </div>

          <div class="row-links">
            <div class="desktop-link">
              <label>Link*</label>
              <base-input v-model="row.link" :showLabel="false" :defaultInputClass="null" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <affiliate-link-form ref="linkForm" @refresh="getLinks" v-if="newLinkModalVisible" />
  </div>
</template>

<script>
import BaseInput from '@atoms/fields/base-input.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'
import AffiliateLinksService from '@services/AffiliateLinksService.js'
import AffiliateLinkForm from '@atoms/dms/operator/sites-data/link-form.vue'

export default {
  components: { BaseInput, AffiliateLinkForm, BaseCheckbox },
  mounted() {
    this.getLinks()
  },
  data() {
    return {
      loading: false,
      params: [],
      showModal: false,
      newLinkModalVisible: false,
      rows: [],
    }
  },
  methods: {
    getLinks() {
      return new Promise((resolve, reject) => {
        AffiliateLinksService.get({
          affiliate_operator_id: this.$route.params.linkId,
          with: [
            'affiliate_operator',
            'affiliate_operator.operator',
            'affiliate_operator.market',
            'affiliate_operator.affiliate',
          ].join(','),
        })
          .then((response) => {
            this.rows = this.mapLinksArray(response.data.result)
            resolve()
          })
          .catch((error) => reject(error))
      })
    },
    async submit() {
      this.loading = true
      await AffiliateLinksService.bulkUpdate({ links: this.rows })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else if (response.data.success) {
            const wording = response.data.result.length === 1 ? 'Link' : 'Links'
            this.showSuccessMessage(`${wording} updated`)
            this.rows = this.mapLinksArray(response.data.result)
            setTimeout(() => {
              this.$emit('refresh', response.data.result)
              this.close()
            }, 200)
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => {
          this.loading = false
        })
    },
    mapLinksArray(links) {
      return links.map((link) => {
        link.delete = false
        link.link = link.link === null ? '' : link.link
        link.original_value = link.link
        link.original_custom_value = link.custom_link
        return link
      })
    },
    linkHasChanged(link) {
      return link.original_value !== link.link || link.original_custom_value !== link.custom_link
    },
    linkType(row, returnClass = false) {
      if (row.name === 'main') {
        return returnClass ? 'bg-red-75' : 'default'
      } else if (row.granular_tracking_link_id) {
        return returnClass ? 'bg-blue-75' : 'granular'
      }
      return returnClass ? 'bg-black-50' : 'custom'
    },
    deleteLink(row) {
      console.log(row, 'row from delete link')
      if (row.name === 'main') {
        return this.showErrorMessage('Main link cannot be deleted.')
      } else if (row.granular_tracking_link_id) {
        return this.showErrorMessage('Granular tracking links cannot be deleted.')
      } else {
        row.delete = !row.delete
      }
    },
    openNewLinkModal() {
      const pendingChanges = this.rows.filter((row) => {
        return row.original_value !== row.link || row.delete
      })
      if (pendingChanges.length > 0) {
        return this.showErrorMessage('Cannot add a new link when you have unsaved changes')
      }
      this.newLinkModalVisible = true
      this.$nextTick(() => {
        this.$refs.linkForm.show(this.$route.params.linkId)
      })
    },
    close() {
      this.loading = false
      this.$refs.modal && this.$refs.modal.classList.remove('show')
      this.showModal = true
      this.$emit('closed')
    },
  },
}
</script>
<style langs="scss" scoped>
.link-row {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}
.row-background {
  background-color: #f8fafc;

  &:hover {
    background-color: #f4f6f9;
  }
}

.is-invalid {
  background-color: #ffcaca;

  &:hover {
    background-color: #ff9f9f;
  }
}
.row-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row-actions {
  display: flex;
  gap: 10px;
}
.row-info {
  display: flex;
  gap: 36px;
  margin-top: 10px;
}
.operator,
.market,
.type {
  border-right: 1px solid #e5e7eb;
  padding-right: 20px;
}
.updated {
  padding-left: 14px;
}
.row-links {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.desktop-link,
.mobile-link {
  width: 100%;
}
.text-muted {
  font-size: 0.85rem;
  color: #6c757d;
}
.header-color {
  color: #94a3b8;
  font-weight: 700;
}
</style>
