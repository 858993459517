<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          v-model="dataType"
          label="Data Type"
          :loadOnMount="false"
          :clearable="false"
          :customOptions="getDataTypes"
          @update:modelValue="onFilterChange"
        />
      </div>
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :loadOnMount="false"
          :clearable="true"
          v-model="dataSubType"
          label="Data Subtype"
          :customOptions="getDataSubTypes"
          @update:modelValue="onFilterChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import AffiliateField from '@models/AffiliateField.js'
const emit = defineEmits(['filterChange'])

const dataType = ref('Operators')
const dataSubType = ref()
const structureFields = AffiliateField.getFieldStructure()

const getDataTypes = computed(() => {
  return Object.keys(structureFields)
})

const getDataSubTypes = computed(() => {
  return Object.keys(structureFields[dataType.value])
})

const onFilterChange = () => {
  emit('filterChange', dataType.value, dataSubType.value)
}
</script>
