<template>
  <div class="form-group" :name="label && slugify(label)">
    <label :for="field" v-if="label">{{ label }}</label>
    <span v-if="required">&nbsp;*&nbsp;</span>
    <span
      v-if="tooltip"
      :data-tooltip="tooltip"
      data-tooltip-color="info"
      data-tooltip-position="right center"
      class="ml-2"
    >
      <i class="uil uil-info-circle"></i>
    </span>
    <div v-if="multiple && (!notifiedByField || (notifiedByField && !isDisabled))" class="d-inline">
      <a class="text-muted ml-2" v-on:click="selectAll(field.name)">select all</a>
      <span class="text-muted ml-1 mr-1">/</span>
      <a class="text-muted" v-on:click="deselectAll(field.name)">deselect all</a>
    </div>

    <template v-if="quickAdd && !readOnly">
      <button class="quick-add-btn ml-2" @click.prevent="openQuickAddModal">+ Quick add</button>
      <quick-add-modal
        v-if="showQuickAddModal"
        ref="quickAddModal"
        :modelAndService="quickAdd"
        :labelOf="label"
        @getNewItems="getItemsAfterSubmit()"
      />
    </template>

    <div v-if="notifiedByField && isDisabled" class="d-inline">
      <span class="text-muted ml-2">{{ notifiedByField.label }}</span>
    </div>
    <multi-select
      ref="select"
      v-model="selected"
      :options="options"
      :multiple="multiple"
      :disabled="disabled || (readOnly && formType && formType === 'edit')"
      :searchable="searchable"
      :placeholder="computedPlaceholder"
      :custom-label="customLabel"
      :track-by="trackBy()"
      :taggable="tag"
      :close-on-select="!multiple"
      @update:modelValue="inputChange"
      @close="close"
      @open="open()"
    >
      <template v-slot:tag="{ option, remove }">
        <span
          class="multiselect__tag tag"
          v-bind:class="{ sortable: sortable }"
          :id="option.id"
          @mousedown="clicked($event)"
          @mouseup="dropped"
        >
          <div v-if="isIndexed(option)" style="display: inline-block">
            <div v-if="!priorityIndexSpinner" class="circle">
              <div style="margin-top: 10px">{{ option.priorityIndex }}</div>
            </div>
            <div v-else class="circle">...</div>
          </div>
          <div v-if="isWaitingToBeIndexed(option)" class="circle">...</div>
          {{ option[selectLabel] }}
          <i aria-hidden="true" tabindex="1" @click="remove(option)" class="close"></i
        ></span>
      </template>
      <template slot="noOptions">
        <li>Loading...</li>
      </template>
    </multi-select>
    <small v-if="helpText" class="form-text text-muted">{{ helpText }}</small>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect'
import GeneralService from '../../services/GeneralService'
import MultiselectRegexPatterns from '@constants/multiselect-regex-patterns.js'
import QuickAddModal from './quick-add-modal.vue'

window.jQuery = jQuery
await import('jquery-ui/dist/jquery-ui')
await import('jquery-ui/ui/widgets/sortable')
await import('jquery-ui/ui/disable-selection')
export default {
  components: { MultiSelect, QuickAddModal },
  data() {
    return {
      options: [],
      selected: null,
      modified: false,
      loaded: false,
      isDisabled: false,
      startSortIndex: null,
      endSortIndex: null,
      labelAttribute: 'label',
      priorityIndexSpinner: false,
      mouseUp: false,
      click: false,
      showQuickAddModal: false,
    }
  },
  props: {
    quickAdd: {
      type: Object,
      default: () => null,
    },
    optionLabelPrettier: {
      type: Boolean,
      default: () => true,
    },
    url: {
      type: String,
    },
    value: {
      required: true,
    },
    searchable: {
      type: Boolean,
      default: () => true,
    },
    multiple: {
      type: Boolean,
      default: () => true,
    },
    helpText: {
      type: String,
    },
    field: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    manualOptions: {
      type: Array,
      default: () => [],
    },
    trackByOption: {
      type: String,
      default: () => 'id',
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
      default: () => null,
    },
    selectLabel: {
      type: String,
      default: () => 'name',
    },
    notifyFields: {
      type: Array,
      default: () => [],
    },
    notifyExtraFields: {
      type: Object,
      default: () => null,
    },
    notifiedByField: {
      type: Object,
      default: () => null,
    },
    tag: {
      type: Boolean,
      default: () => false,
    },
    tooltip: {
      type: String,
    },
    customInputChange: {
      type: Function,
    },
    readOnly: {
      default: () => false,
    },
    formType: {
      default: () => null,
      type: String,
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    fieldObject: {
      default: () => null,
      type: Object,
    },
    type: {
      type: String,
      default: 'list-many-sorted',
    },
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder) {
        return this.placeholder
      }
      return this.multiple ? 'Select one or multiple options' : 'Select an option'
    },
    sortable() {
      return this.fieldObject && this.fieldObject.type == 'list-many-sortable'
    },
  },
  mounted() {
    this.selected = this.value

    if (this.value && this.url) {
      this.getOptions(this.url)
    }

    // field is not disabled if notifiedByField has data
    if (this.notifiedByField && this.$parent.object) {
      this.isDisabled = !this.$parent.object[this.notifiedByField.field]
    }

    if (this.disabled) {
      this.isDisabled = this.disabled
    }

    if (this.sortable) {
      this.createSortable()
    }
    this.$emit('sync', this.field, this.value, this.trackBy())
  },
  methods: {
    openQuickAddModal() {
      this.showQuickAddModal = true
      this.$nextTick(() => {
        this.$refs?.quickAddModal.show()
      })
    },
    getItemsAfterSubmit() {
      this.getOptions()
    },
    clicked(e) {
      let that = this
      that.mouseUp = false
      that.click = true
      e.target.addEventListener(
        'mouseleave',
        () => {
          if (!that.mouseUp && that.click) {
            $(that.$refs.select.$refs.tags).find('div.multiselect__tags-wrap').sortable('cancel')
          }
        },
        { once: true }
      )
    },
    isWaitingToBeIndexed(option) {
      if (this.isIndexed(option)) return false

      let that = this
      return MultiselectRegexPatterns.patterns.some(function (pattern) {
        let match = that.$route.path.match(pattern)
        if (match && match.length > 0 && match[0] === that.$route.path) {
          return true
        }
      })
    },
    isIndexed(option) {
      return this.fieldObject.priorityIndex && typeof option.priorityIndex === 'number'
    },
    async dropped() {
      this.mouseUp = true
      this.click = false
      await this.reassignIndexToObject(this.selected)
    },
    customLabel(value) {
      if (value === Object(value)) {
        return this.findInObject(this.selectLabel.split('.'), value)
      }

      return this.optionLabelPrettier ? this.$prettyLabels(value) : value
    },
    setSelected(selected) {
      this.selected = selected
      this.$emit('sync', this.field, this.value, this.trackBy())
    },
    findPreselectedValue() {
      // if value is an ID, we need to find the entire object (to populate the label)
      if (this.value && this.findInObject(this.trackByOption.split('.'), this.value)) {
        this.options.some((option) => {
          if (
            this.findInObject(this.trackByOption.split('.'), option) ===
            this.findInObject(this.trackByOption.split('.'), this.value)
          ) {
            this.selected = option
            return true
          }
        })
      }
    },
    getOptions(url = false) {
      let data = {}
      // get possible extra data from notifiedByField (i.e: affiliate_id=1)
      if (this.notifiedByField && this.$parent.object) {
        data[this.notifiedByField.field] = this.$parent.object[this.notifiedByField.field]
      }
      return new Promise((resolve) => {
        if (url) {
          GeneralService.fetchItems(url, data)
            .then((response) => {
              this.options = response.data.result
              this.isDisabled = false
              this.findPreselectedValue()
              resolve()
            })
            .catch(() => this.showErrorMessage(`Failed to fetch options for ${this.label}`))
        } else {
          if (!this.manualOptions) {
            // eslint-disable-next-line
            console.error('base-multiselect error: Please provide an URL or an array with manualOptions')
          } else {
            this.options = this.manualOptions
            this.isDisabled = false
            resolve()
          }
        }
      })
    },
    refreshOptions(param, value) {
      this.selected = null
      if (value) {
        this.getOptions(`${this.url}?${param}=${value}`)
      } else {
        if (this.notifiedByField) {
          this.isDisabled = true
          this.$emit('sync', this.field, null, this.trackBy())
        }
      }
    },
    clearAndEnable() {
      this.isDisabled = false
      this.options = []
      this.selected = null
    },
    trackBy() {
      return this.manualOptions && this.manualOptions.length > 0
        ? this.trackByOption
          ? this.trackByOption
          : ''
        : this.trackByOption
    },
    assignIndexToObject(value) {
      return Promise.resolve(value.forEach((item, index) => (item.priorityIndex = index + 1)))
    },
    async reassignIndexToObject(value) {
      if (this.fieldObject.priorityIndex) {
        let children = document.querySelector('div.multiselect__tags-wrap').children
        this.priorityIndexSpinner = true
        await GeneralService.fetchItems(this.url, {})
          .then((response) => {
            this.options = response.data.result
            children.forEach(function (child, index) {
              if (child.id) {
                value.some(function (obj) {
                  if (obj.id == child.id) {
                    obj.priorityIndex = index + 1
                    return true
                  }
                })
              }
            })
            this.priorityIndexSpinner = false
          })
          .catch(() => this.showErrorMessage(`Failed to fetch options for ${this.label}`))
      }
    },
    async inputChange(value) {
      if (this.isDisabled) return
      await this.reassignIndexToObject(value)
      if (this.notifyFields) {
        this.notifyFields.forEach((item) => {
          const id = value && value.id ? value.id : null
          const defaultValue =
            id && typeof item.default !== 'undefined' && item.default && item.default.length > 0
              ? this.multiple
                ? item.default
                : item.default[0]
              : null
          this.$emit('notify', item.field, item.param, id)
          this.$emit('sync', item.field, defaultValue, this.trackBy())
        })
      }

      if (this.notifyExtraFields) {
        this.$emit('notifyExtraFields', this.notifyExtraFields)
      }

      if (this.sortable) {
        this.createSortable()
      }

      this.$emit('sync', this.field, value, this.trackBy())

      if (this.customInputChange) {
        return this.customInputChange(value)
      }

      if (this.fieldObject && Object.hasOwn(this.fieldObject, 'onSelectHide')) {
        this.fieldObject.onSelectHide.forEach((toHide) => {
          this.$emit('hide', toHide, this.field, value)
        })
      }
    },
    async selectAll() {
      if (!this.isDisabled) {
        if (this.notifiedByFieldHasData() || this.options.length == 0) {
          this.getOptions(this.url).then(async () => {
            this.selected = this.options
            if (this.fieldObject.priorityIndex) {
              await this.assignIndexToObject(this.selected)
            }

            if (this.sortable) {
              this.$nextTick(() => {
                this.createSortable()
              })
            }

            this.$emit('sync', this.field, this.selected, this.trackBy())
          })
        } else {
          if (this.options.length > 0) {
            this.selected = this.options
            await this.reassignIndexToObject(this.selected)

            if (this.sortable) {
              this.$nextTick(() => {
                this.createSortable()
              })
            }

            this.$emit('sync', this.field, this.selected, this.trackBy())
          }
        }
      }
    },
    deselectAll() {
      if (!this.isDisabled) {
        this.selected = []
        this.options = []
        this.$emit('sync', this.field, this.selected)
      }
    },
    notifiedByFieldHasData() {
      if (!this.notifiedByField || !this.$parent.object) {
        return false
      }
      return this.$parent.object[this.notifiedByField.field]
    },
    createSortable() {
      try {
        // check if it was initialized before, if so destroy, so it can reach the create function again
        $(this.$refs.select.$refs.tags).find('div.multiselect__tags-wrap').sortable('destroy')
      } catch (e) {
        // not initalized yet
      }

      // re-create so that created function is always run
      $(this.$refs.select.$refs.tags)
        .find('div.multiselect__tags-wrap')
        .sortable({
          containment: 'parent',
          cursor: 'grab',
          update: () => {
            let result = []
            const sortedIds = $(this.$refs.select.$refs.tags)
              .find('div.multiselect__tags-wrap')
              .sortable('toArray')
              .map((i) => Number(i))

            this.selected.forEach(function (a) {
              result[sortedIds.indexOf(a.id)] = a
            })

            this.$emit('sync', this.field, result, this.trackBy())
          },
          create: (e) => {
            this.$nextTick(() => {
              // set as display block for better handling of sorting
              $(e.target).css('display', 'block')
              $(e.target).css('overflow', 'auto')
            })
          },
        })
    },
    close() {
      $(this.$refs.select.$el).attr('close-modal-inputs', true)
    },
    open() {
      $(this.$refs.select.$el).attr('close-modal-inputs', false)
      this.getOptions(this.url)
    },
  },
}
</script>

<style scoped>
.quick-add-btn {
  padding: 4px 4px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 8px;
  background-color: #f1f2f9;
  box-shadow: none;
  color: #1642a8;
  border-color: transparent;
}
.circle {
  background: #007fff;
  border-radius: 50%;
  font-size: 10px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  text-align: center;
  display: inline-block;
}
.multiselect__tag {
  border-radius: 500px;
}
</style>
