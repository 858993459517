<template>
  <div>
    <SitesDataFilter @filterChange="onFilterChange" />
    <data-table
      :key="tableKey"
      model="AffiliateOperator"
      service-file="AffiliateOperatorsService"
      :path="path"
      ref="list"
      :column-templates="columnTemplates()"
      :columns="columns()"
      :rowActionButtons="rowActions()"
      :serviceParams="[operator_id]"
      :showMinimalRemove="true"
      :create-defaults="createDefaults()"
      :show-edit-modal="false"
      :show-create="false"
      :customRowClick="customRowClick"
      :customActionButton="{
        action: sitesDataCreatePage,
        class: 'btn btn-primary',
        title: 'Create',
        icon: 'uil uil-plus',
      }"
      :show-remove="false"
      :checkStatusOfOperators="checkStatusOfOperators"
      :enable-bulk-select="true"
      :default-filters="filters"
      @bulkClickByIds="bulkClick"
      getCustomDataFunctionName="getArrayOfAffiliateOperatorsObjects"
    />
    <site-data-clone ref="siteDataCloneModal" @refresh="refresh" />
    <AffiliateOperatorsEditBulk
      v-if="showBulkEdit"
      ref="bulkEdit"
      @refresh="$refs.list && $refs.list.search()"
      @closed="showBulkEdit = false"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount, computed, nextTick, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import DataTable from '@templates/table/table.vue'
import TableColumnTemplate from '@constants/table-column-template.js'
import AffiliateOperatorsEditBulk from '@molecules/dms/operator/sites-data/edit-bulk.vue'
import SiteDataClone from '@molecules/dms/operator/sites-data/clone-modal.vue'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'
import PagesService from '@services/PagesService.js'
import { useStore } from 'vuex'
import SitesDataFilter from './sites-data-filter.vue'

const route = useRoute()
const router = useRouter()
const store = useStore()
const { proxy } = getCurrentInstance()

const path = ref(
  `hercules/operators/affiliate-operators?operator_id=${route.params['id']}&with=market,affiliate,ribbons,linked_group&exact_match=status`
)
const operator_id = ref(route.params['id'])
const filters = ref(null)
const showBulkEdit = ref(false)
const showInfoModal = ref(false)
const pageRelations = ref([])
const tableKey = ref(0)
const isMarketOnStore = computed(() => store.state.editPage.marketId)

onMounted(() => {
  proxy.setTitle(`${proxy.$parent.$parent.title ? proxy.$parent.$parent.title + ':' : ''} ${route.meta.name}`)
})

const sitesDataCreatePage = () => {
  router.push(`/operators/${route.params.id}/sites-data/create`)
}

const dispatchToplistParams = (row) => {
  store.dispatch('data-table/setToplistParams', {
    markets_label: row.market?.label || '',
    sites_name: row.affiliate?.name || '',
    lists_type: row.type || '',
  })
}
const customRowClick = (event, row) => {
  dispatchToplistParams(row)
  router.push(`/operators/${route.params.id}/sites-data/edit/${row.id}`)
}

onBeforeMount(() => {
  if (route.query) {
    filters.value = route.query
  }
  if (isMarketOnStore.value) {
    onFilterChange()
  }
})

const getInitOperatorStatus = computed(() => {
  return store.state.dynamicForm.initFormObject && store.state.dynamicForm.initFormObject.status
})

const confirmDelete = async ({ title, content, htmlForPageRelations, actionButtonTitle }) => {
  return await openDialog(ConfirmDialog, { title, content, htmlForPageRelations, actionButtonTitle })
}

const rowActions = () => {
  let actions = [
    {
      action: toplistsAction,
      title: 'Toplists',
      icon: 'uil uil-list-ul',
      class: 'btn-extra',
    },
    {
      action: bonusAction,
      title: 'Bonuses',
      icon: 'uil uil-medal',
      class: 'btn-info',
    },
    {
      action: linksAction,
      title: 'Links',
      icon: 'uil uil-link',
      class: 'btn-secondary',
    },
    {
      action: siteDataCloneAction,
      title: 'Clone',
      permission: 'post.operators/affiliate-operators',
      icon: 'uil uil-share-alt',
      class: 'bg-orange-75',
    },
  ]

  if (proxy.methodAllowed('delete', path.value)) {
    actions.push({
      action: remove,
      title: 'Delete',
      icon: 'uil uil-trash-alt',
      class: 'btn-danger',
    })
  }

  return actions
}

const checkStatusOfOperators = (data) => {
  if (data.status !== 'active' && getInitOperatorStatus.value !== data.status) {
    showInfoModal.value = true
    nextTick(() => {
      proxy.$refs.infoModal.show(operator_id.value, data)
    })
  }
}

const bulkClick = (ids) => {
  showBulkEdit.value = true
  nextTick(() => {
    proxy.$refs.bulkEdit.show(ids)
  })
}

const columns = () => {
  return [
    { field: 'type', type: 'text', filterable: true },
    { field: 'site', type: 'text', filterable: true },
    {
      field: 'market.label',
      order_key: 'market_label',
      type: 'text',
      filterable: true,
      label: 'Market',
      template: TableColumnTemplate.MARKET_LABEL,
    },
    {
      field: 'status',
      type: 'text',
      advancedFilter: true,
      showModalAdvanced: false,
      filterable: true,
      customOptions: [
        { label: 'All', id: null },
        { label: 'Active', id: 'active' },
        { label: 'Inactive', id: 'inactive' },
        { label: 'Restricted', id: 'restricted' },
        { label: 'Coming soon', id: 'coming_soon' },
        { label: 'Blacklisted', id: 'blacklisted' },
        { label: 'Not recommended', id: 'not_recommended' },
      ],
      basicFilterType: 'select',
      optionLabelKey: 'label',
      template: TableColumnTemplate.OPERATOR_STATUS,
    },
    { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
  ]
}

const toplistsAction = (row) => {
  dispatchToplistParams(row)
  router.push({
    path: `/operators/${row.operator_id}/toplists`,
    query: { markets_label: row.market.label, sites_name: row.affiliate.name, lists_type: row.type },
  })
}

const bonusAction = (row) => {
  dispatchToplistParams(row)
  router.push(`/operators/${route.params.id}/sites-data/bonuses/${row.id}`)
}

const linksAction = (row) => {
  dispatchToplistParams(row)
  router.push(`/operators/${route.params.id}/sites-data/links/${row.id}`)
}

const siteDataCloneAction = (row) => {
  proxy.$refs.siteDataCloneModal.show(row)
}

const checkPageRelations = async (id) => {
  try {
    const response = await PagesService.get({ relation_type: 'operator', relation_id: id })
    if (response.data.result) {
      pageRelations.value = response.data.result
    } else {
      console.error('No page relations found or error in response:', response)
      pageRelations.value = []
    }
  } catch (error) {
    console.error('Error fetching page relations:', error)
    proxy.showErrorMessage('Error fetching page relations')
  }
}

const remove = async (row) => {
  await checkPageRelations(row.id)

  try {
    let htmlForPageRelations = ''

    if (pageRelations.value.length > 0) {
      const relatedPageIds = pageRelations.value
        .map((item) => `- <a href='/site-pages/${item.id}' target="_blank"> ${item.title} </a>`)
        .join('<br>')

      htmlForPageRelations = `<p>This item is used as a page relation for the following pages:<br>${relatedPageIds}</p>`
    } else {
      htmlForPageRelations = `<p>This item is not used as a page relation for any existing pages. It is safe to delete.</p>`
    }

    const confirm = await confirmDelete({
      title: 'Warning',
      content: 'Are you sure you want to delete this item?',
      htmlForPageRelations: htmlForPageRelations,
      actionButtonTitle: 'Delete item',
    })

    if (confirm) {
      proxy.loading = true

      if (row.id) {
        const deleteResponse = await AffiliateOperatorsService.remove({ id: row.id })

        if (deleteResponse.data?.messages) {
          proxy.showErrorMessages(deleteResponse.data.messages)
        } else {
          proxy.showSuccessMessage('Item removed successfully.')
        }
      }
    }
  } catch (error) {
    console.error('Error removing item:', error)
    proxy.showUnknownErrorMessage()
  } finally {
    proxy.loading = false
    refresh()
  }
}

const createDefaults = () => {
  return {
    rating: 4,
    operator_id: parseInt(route.params['id']),
  }
}

const columnTemplates = () => {
  return Object.assign({
    site: function (row) {
      return row.affiliate && row.affiliate.website
    },
    status: function (row) {
      return row.status.label
    },
  })
}

const refresh = () => {
  proxy.$refs.list.search()
}

const onFilterChange = (market, type, site) => {
  let newPath = `hercules/operators/affiliate-operators?operator_id=${route.params['id']}&with=market,affiliate,ribbons,linked_group&exact_match=type`

  const queryParams = []

  if (market || isMarketOnStore.value) {
    let isMarketSelected = market ? market : isMarketOnStore.value
    queryParams.push(`market.label=${isMarketSelected}`)
  }

  if (type) {
    queryParams.push(`type=${type}`)
  }

  if (site) {
    queryParams.push(`site=${site}`)
  }

  if (queryParams.length) {
    newPath += `&${queryParams.join('&')}`
  }

  path.value = newPath
  tableKey.value++
}
</script>
