import FieldType from '@constants/field-type.js'

export default {
  getForm() {
    return [
      {
        name: 'name',
        required: true,
        type: 'text',
      },
      {
        name: 'active',
        require: false,
        type: 'checkbox',
      },
    ]
  },
  getConfig() {
    return {
      name: 'Fields Management',
      url: 'operators/affiliates/field-management',
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
  getFieldStructure() {
    return {
      Operators: {
        'Site Data': [],
        'Site Data Bonuses': [],
      },
    }
  },
}
