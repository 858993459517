<template>
  <div>
    <div class="d-flex justify-content-end mb-2">
      <div class="font-size-sm bg-orange-10 rounded-lg p-2">
        <span>
          <i class="uil uil-exclamation-triangle mr-1 text-orange"></i>
          Disabling fields will affect all users with access to this website
        </span>
      </div>
    </div>
    <FieldManagementFilter @filterChange="onFilterChanged" />
    <data-table
      model="AffiliateField"
      :enable-row-click="false"
      :path="path"
      ref="table"
      :columns="columns()"
      :column-templates="columnTemplates()"
      :show-edit-modal="false"
      :show-create="false"
      :key="refreshTable"
    />
  </div>
</template>

<script setup lang="jsx">
import { ref, getCurrentInstance, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TableColumnTemplate from '@constants/table-column-template.js'
import FieldManagementFilter from './field-management-filter.vue'
import EnabledActionColumn from '@molecules/table/cell/enabled-action.vue'
import AffiliateFieldService from '@services/AffiliateFieldService.js'

const { proxy } = getCurrentInstance()

const route = useRoute()
const list = ref(null)
const refreshTable = ref(0)
const affiliate_id = route.params['id']

const path = ref(
  `hercules/operators/affiliates/field-management?with=field&affiliate_id=${affiliate_id}&type=Operators`
)

const onFilterChanged = (dataType, dataSubType) => {
  if (!dataType || !dataSubType) {
    return
  }
  path.value = `hercules/operators/affiliates/field-management?with=field&affiliate_id=${affiliate_id}&type=${dataType}&sub_type=${dataSubType}`
  refreshTable.value += 1
}

const columns = () => {
  return [
    { field: 'name', type: 'text', filterable: true },
    {
      field: 'active',
      label: 'Enabled',
      type: 'text',
      filterable: true,
      advancedFilter: true,
      clearable: true,
      allowNullOption: true,
      customOptions: [
        { label: 'Enabled', id: '1' },
        { label: 'Disabled', id: '0' },
      ],
      basicFilterType: 'select',
      optionLabelKey: 'label',
      width: '150px',
      sortable: true,
    },
  ]
}

const actionColumnClick = (id, field, value) => {
  proxy.$refs.table.isLoading = true
  let object = proxy.$refs.table.rows.find((item) => item.id === id)
  object = { id: object.id, active: object.active }

  object[field] = value
  AffiliateFieldService.update(object.id, object)
    .then(() => {
      proxy.$refs.table.search()
      proxy.showSuccessMessage('Field updated')
    })
    .catch(() => {
      proxy.showErrorMessage('Failed to update affiliate field')
      proxy.$refs.table.isLoading = false
    })
}

const columnTemplates = () => {
  return {
    name: function (row) {
      return row.field.name
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase())
    },
    active: function (row) {
      return <EnabledActionColumn row={row} field={'active'} onColumnClick={actionColumnClick} />
    },
  }
}
</script>
