import Logs from '@pages/logs/logs.vue'
import ActivityLogs from '@pages/logs/new-activity-logs.vue'
import OldActivityLogs from '@pages/logs/old-activity-logs.vue'
import ListenerPerformanceLogs from '@pages/logs/listener-performance-logs.vue'

export default [
  {
    path: '/activity-logs',
    component: ActivityLogs,
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Activity Logs',
      permission: 'view.logs/activity-logs',
    },
  },
  {
    path: '/old-activity-logs',
    component: OldActivityLogs,
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Old Activity Logs',
      permission: 'view.logs/activity-logs',
    },
  },
  {
    path: '/listener-performance-logs',
    component: ListenerPerformanceLogs,
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Listener Performance Logs',
      permission: 'view.logs/activity-logs',
    },
  },
  {
    path: '/logs',
    component: Logs,
    meta: {
      parent: 'Home',
      auth: true,
      name: 'Logs',
    },
  },
]
