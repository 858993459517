<script setup>
import { ref, onMounted, getCurrentInstance, computed } from 'vue'
import DynamicForm from '@/components/fields/DynamicForm.vue'
import Casino from '@/models/Operator.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import OperatorsService from '@services/OperatorsService.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { proxy } = getCurrentInstance()

const object = ref({})
const structure = ref({ list: [] })
const config = Casino.getConfig()
const sportsBookFields = Casino.getSportsBookFields()
const sportsBookFieldValues = ref([])
const isLoading = computed(() => store.state.editPage.isLoading)

// Confirm dialog for deletion
const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

// Function to get operator data
const getOperator = () => {
  OperatorsService.getOne(route.params['id'], {
    with: [
      'game_types',
      'software',
      'selling_points',
      'languages',
      'countries',
      'restricted_countries',
      'restricted_vip_countries',
      'blacklisted_countries',
      'support_types',
      'game_categories',
      'currencies',
      'bettingTypes',
      'sportCategories',
      'oddsTypes',
      'betting_apps',
    ].join(','),
  }).then((response) => {
    object.value = {
      ...response.data.result[0],
    }
    getStructure()
    getSportBookFieldValues()
  })
}

// Populate sports book field values
const getSportBookFieldValues = () => {
  const fields = []
  fields['bet_builder'] = object.value.bet_builder
  fields['live_streaming'] = object.value.live_streaming
  fields['cash_out'] = object.value.cash_out
  fields['in_play_betting'] = object.value.in_play_betting
  fields['acca_insurance'] = object.value.acca_insurance
  fields['sport_categories'] = object.value.sport_categories
    ? object.value.sport_categories.map((item) => item.id)
    : []
  fields['betting_types'] = object.value.betting_types
    ? object.value.betting_types.map((item) => item.id)
    : []
  fields['odds_types'] = object.value.odds_types ? object.value.odds_types.map((item) => item.name) : []
  sportsBookFieldValues.value = fields
}

// Fetch structure data
const getStructure = () => {
  structure.value = Casino.getStructure()
}

// Submit handler
const submit = () => {
  object.value = {
    ...object.value,
    ...sportsBookFieldValues.value,
  }

  delete object.value.status_changed_by
  proxy.$refs.form
    .process(object.value)
    .then((response) => {
      return OperatorsService.update(response)
    })
    .then((response) => {
      if (response.data.messages) {
        proxy.showErrorMessages(response.data.messages)
      } else {
        console.log('on sucesss')
        proxy.showSuccessMessage(`${structure.value.config.name} updated`)
        proxy.$parent.$parent.getOperator()
      }
    })
    .catch(() => proxy.showUnknownErrorMessage)
    .finally(() => {
      proxy.$refs.form.isLoading = false
    })
}

// Remove handler
const remove = async () => {
  if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
    OperatorsService.remove({ id: route.params['id'] })
      .then((response) => {
        if (response.data.messages) {
          proxy.showErrorMessages(response.data.messages)
        } else {
          proxy.showSuccessMessage(`${structure.value.config.name} removed`)
          router.push('/operators')
        }
      })
      .catch(proxy.showUnknownErrorMessage)
  }
}

// Mounted hook to load initial data and set title
onMounted(() => {
  getOperator()
  proxy.setTitle(`${proxy.$parent?.title ? proxy.$parent.title + ':' : ''} General`)
})
</script>
<template>
  <div class="text-center" v-if="isLoading">
    <span>Loading...</span>
  </div>
  <div v-else>
    <div class="card">
      <dynamic-form
        ref="form"
        form-type="edit"
        :inCard="true"
        :formFields="structure.list"
        :structure="structure"
        :sportsBookFields="sportsBookFields"
        :path="config.url"
        :object="object"
        :on-submit="submit"
        :on-remove="remove"
        :sportsBookFieldValues="sportsBookFieldValues"
      />
    </div>
  </div>
</template>
