<template>
  <div class="card" v-click-outside="handleFormDirty">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <h2 class="modal-title d-inline mr-2">Bonuses</h2>
        <div class="modal-title-buttons d-flex justify-content-between">
          <button v-if="types.length > 0" class="btn btn-info btn-sm mr-3" v-on:click="$refs.typeForm.show()">
            Add new
          </button>
        </div>
      </div>
      <div class="form-group text-right d-flex">
        <div v-if="types.length > 0 && typeObject != null" class="mr-2">
          <select class="form-control" v-model="typeId" v-on:change="fetchType(typeId)" title="links">
            <option :value="type.id" v-for="(type, key) in types" :key="key">{{ type.name }}</option>
          </select>
        </div>
        <div>
          <button type="submit" class="btn btn-danger mr-2" @click="remove" :class="{ loading }">
            <i class="uil uil-trash"></i>
            Delete
          </button>
          <button type="submit" class="btn btn-primary" @click="submit" :class="{ loading }">
            <i class="uil uil-check"></i>
            Save
          </button>
        </div>
      </div>
    </div>
    <div class="card-body loading-opacity" v-if="typeObject != null">
      <div class="collapse mt-3 show">
        <form v-on:submit.prevent="submit" v-if="typeObject" @click="setInitObject">
          <div class="d-flex gap-3">
            <base-input type="text" label="name" v-model="typeObject.name" :required="true" class="w-100" />
            <base-select
              label="Status"
              v-model="typeObject.status"
              :customOptions="['active', 'expired']"
              class="w-100"
            />
          </div>
          <div class="d-flex gap-3">
            <div class="form-group mt-2 w-100">
              <label>Bonus Text</label>
              <textarea
                rows="1"
                ref="textArea"
                class="form-control"
                v-model="typeObject.format"
                v-on:input="renderExample"
              ></textarea>
            </div>
            <div class="form-group mt-2 w-100">
              <label>Bonus Preview</label>
              <div class="form-control font-weight-normal disable-preview" v-html="example" />
            </div>
          </div>
          <div class="form-group">
            <label class="d-block">Available variables</label>
            <p v-if="typeObject.values.length === 0">No type values added yet</p>
          </div>
          <div class="bonus-preview">
            <span
              class="btn-sm badge-light m-1 pr-0 d-inline-flex align-items-baseline bonus-badge add-new-variable btn btn-info"
              @click.stop="showPopover({ value: '', id: 0 })"
            >
              <span class="p-05"> Add new variable <i class="uil uil-plus"></i></span>
              <span
                ref="add-popover"
                v-show="bonusPopover === 0"
                class="position-absolute bg-white border rounded shadow-sm bonus-popover p-2"
              >
                <base-input
                  type="text"
                  label="name"
                  v-model="bonusInputName"
                  class="w-100 bonus-popover-input"
                  :required="true"
                />
                <base-input
                  type="text"
                  label="value"
                  v-model="bonusInputValue"
                  class="w-100 bonus-popover-input"
                  :required="true"
                />
                <div class="d-flex">
                  <button
                    class="btn btn-primary mr-1"
                    @click.stop.prevent="addNewVariable"
                    :class="{ loading: loadingVariable }"
                  >
                    Add
                  </button>
                </div>
              </span>
            </span>
            <span
              class="btn btn-sm bg-white m-1 p-0 d-inline-flex align-items-baseline justify-content-between bonus-badge"
              v-for="(value, sKey) in typeObject.values"
              :key="sKey"
            >
              <span @click.stop="addValueToFormat(value.name)" class="mx-2 p-2">{{ value.name }}</span>
              <span @click.stop="showPopover(value)" class="mr-0 p-2 show-popover-btn"
                ><i class="uil uil-edit"></i>
              </span>
              <span
                @click.stop="bonusPopover = value.id"
                ref="update-popover"
                v-show="bonusPopover === value.id"
                class="position-absolute bg-white p-2 border rounded shadow-sm bonus-popover"
              >
                <base-input
                  v-if="isFieldVisible('bonus_value')"
                  ref="bonusInput"
                  type="text"
                  label="value"
                  v-model="bonusInputValue"
                  class="w-100 bonus-popover-input"
                  :required="true"
                />
                <div class="d-flex">
                  <button
                    class="btn btn-primary mr-1"
                    @click.stop.prevent="updateBonusVariable(value)"
                    :class="{ loading: loadingVariable }"
                  >
                    Update
                  </button>
                  <button
                    class="btn btn-danger"
                    @click.prevent="deleteBonusVariable(value)"
                    :class="{ loading: loadingVariable }"
                  >
                    Delete
                  </button>
                </div>
              </span>
            </span>
          </div>
          <div>
            <label class="d-block"><p>Click on a variable to add it to the bonus text</p></label>
          </div>
          <base-multiselect
            :key="'one_liner_types' + rerenderBonusTypes"
            :multiple="true"
            ref="bonusTypes"
            id="one_liner_types"
            path="hercules/operators/one-liner-types"
            field="one_liner_types"
            name="one_liner_types"
            label="Bonus Types"
            selectLabel="name"
            trackByOption="id"
            :value="typeObject.one_liner_types"
            @sync="syncValues"
          />
          <div class="d-flex gap-3">
            <base-input
              v-if="isFieldVisible('start_date')"
              class="w-100"
              type="date"
              label="start date"
              v-model="typeObject.start_date"
              :required="false"
            />
            <base-input
              v-if="isFieldVisible('end_date')"
              class="w-100"
              type="date"
              label="end date"
              v-model="typeObject.end_date"
              :required="false"
            />
          </div>
          <div class="d-flex gap-3">
            <base-input
              v-if="isFieldVisible('promo_code')"
              type="text"
              label="promo_code"
              v-model="typeObject.promo_code"
              :required="false"
              class="w-100"
            />
            <base-input
              v-if="isFieldVisible('max_bonus_available')"
              type="text"
              label="max_bonus_available"
              v-model="typeObject.max_bonus_available"
              :required="false"
              class="w-100"
            />
          </div>
          <base-input
            v-if="isFieldVisible('bonus_value')"
            type="text"
            label="bonus_value"
            v-model="typeObject.bonus_value"
            :required="false"
          />
          <base-input
            v-if="isFieldVisible('slug')"
            type="text"
            label="slug"
            v-model="typeObject.slug"
            :required="false"
          />
          <base-nested-checkbox
            v-if="isFieldVisible('restricted_countries')"
            :key="'restricted_countries' + reRenderMultiSelect"
            label="Restricted countries"
            path="hercules/globals/countries?with=children&hide_children=0&orderBy=name&ascending=1&with_no_assets=1&fields_only[]=id&fields_only[]=name&fields_only[]=parent_id"
            field="restricted_countries"
            validate-changes="true"
            type="list-many-nested-checkbox"
            :value="typeObject.restricted_countries"
            @sync="syncValues"
          ></base-nested-checkbox>
          <base-multiselect
            v-if="isFieldVisible('allowed_games')"
            field="allowed_games"
            name="allowed_games"
            :key="'allowed_games' + reRenderMultiSelect"
            label="Allowed games"
            :multiple="true"
            :max="-1"
            selectLabel="name"
            trackByOption="id"
            path="hercules/games/games?orderBy=name&ascending=1&fields_only[]=id&fields_only[]=name"
            :value="typeObject.allowed_games"
            @sync="syncValues"
          ></base-multiselect>
          <div class="d-flex gap-3">
            <base-input
              v-if="isFieldVisible('min_deposit')"
              type="text"
              label="min_deposit"
              v-model="typeObject.min_deposit"
              :required="false"
              class="w-100"
            />
            <base-input
              v-if="isFieldVisible('wagering_requirements')"
              type="text"
              label="wagering_requirements"
              v-model="typeObject.wagering_requirements"
              :required="false"
              class="w-100"
            />
          </div>
          <base-rich-text
            v-if="isFieldVisible('terms_and_conditions')"
            :key="'baseRichText-' + reRender"
            :modelValue="typeObject.terms_and_conditions"
            label="Terms and Conditions"
            height="120"
            :hiddenTools="[
              'image',
              'heading',
              'anchor',
              'table',
              'blockQuote',
              'horizontalRule',
              'sourceCode',
              'FullScreenToggle',
            ]"
            @valueChanged="(value) => (typeObject.terms_and_conditions = value)"
            :trackingLink="true"
          />
          <base-checkbox
            v-if="isFieldVisible('terms_and_conditions_enabled')"
            :key="'baseCheckbox-' + reRender"
            label="Enable Terms and Conditions"
            v-model="typeObject.terms_and_conditions_enabled"
          />
        </form>
      </div>
      <type-form
        ref="typeForm"
        :type-id="typeObject.id"
        :parent-id="getParentId()"
        :model="model"
        v-on:refresh="fetchTypes"
      />
    </div>
  </div>
</template>

<script>
import TypeForm from '@atoms/dms/bonus/type-form.vue'
import BonusService from '@services/BonusesService.js'
import BaseInput from '@atoms/fields/base-input.vue'
import BaseSelect from '@/components/fields/base-select.vue'
import BaseMultiselect from '@/components/fields/base-multiselect.vue'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'
import ConfirmDialog from '@atoms/misc/confirm-dialog.vue'
import { openDialog } from 'vue3-promise-dialog'
import _ from 'lodash'
import BaseNestedCheckbox from '@/components/fields/base-nested-checkbox.vue'
import BaseRichText from '@atoms/fields/base-rich-text.vue'
import BaseCheckbox from '@atoms/fields/base-checkbox.vue'

const confirmDelete = async (title, content) => {
  return await openDialog(ConfirmDialog, { title, content })
}

let service

export default {
  components: {
    BaseNestedCheckbox,
    BaseInput,
    BaseSelect,
    BaseMultiselect,
    TypeForm,
    BaseRichText,
    BaseCheckbox,
  },
  directives: {
    clickOutsidePopover: {
      mounted: function (el, binding) {
        el.clickOutsideEvent = function (event) {
          // Here we'll check that the click was outside the element and call method
          if (!(el === event.target || el.contains(event.target))) {
            // And if it did, call method provided in attribute value
            binding.value(event)
          }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
      },
      unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
      },
    },
  },
  data() {
    return {
      reRenderMultiSelect: 0,
      rerenderBonusTypes: 0,
      loading: false,
      loadingVariable: false,
      types: [],
      typeId: null,
      typeObject: null,
      example: '',
      isActive: false,
      modalType: 'modal',
      operatorId: null,
      operatorTypes: ['sportsbook', 'dfs'],
      bonusPopover: false,
      bonusInputValue: '',
      bonusInputName: '',
      reRender: 0,
      initialTypeObject: {},
      model: 'affiliate',
      bonusType: 'bonus',
      hiddenFields: [],
      affiliateId: null,
    }
  },
  beforeMount() {
    service = this.model === 'affiliate' ? AffiliateOperatorsService : BonusService
  },
  mounted() {
    this.show()
    setTimeout(async () => {
      await this.fetchHiddenFields()
    }, 500)
  },
  computed: {
    affiliateSiteId() {
      return this.$store?.state['editPage']?.extraOperatorInfo?.affiliate_id
    },
  },
  methods: {
    async fetchHiddenFields() {
      await service
        .getVisibleFields(this.affiliateSiteId, 'Site Data Bonuses')
        .then((response) => {
          this.hiddenFields = response.data.result.map((item) => item.field.name)
        })
        .catch((error) => {
          console.error('Error fetching hidden fields:', error)
        })
    },
    isFieldVisible(label) {
      return !this.hiddenFields.includes(label)
    },
    updateBonusVariable(bonusType) {
      this.loadingVariable = true
      service
        .updateTypeValue(this.getParentId(), { ...bonusType, value: this.bonusInputValue })
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Variable updated')
            this.typeObject.values.find((item, index) => {
              if (item.id === bonusType?.id) {
                this.typeObject.values[index] = { ...bonusType, value: this.bonusInputValue }
              }
              this.bonusPopover = null
              this.renderExample()
            })
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loadingVariable = false))
    },
    deleteBonusVariable(bonusType) {
      this.loadingVariable = true
      service
        .deleteTypeValue(this.getParentId(), bonusType)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Variable deleted')
            const foundIndex = this.typeObject.values.findIndex((variable) => variable.id === bonusType.id)
            if (foundIndex !== -1) {
              const variablePattern = new RegExp(`\\[${this.typeObject.values[foundIndex].name}\\]`, 'g')
              this.typeObject.format = this.typeObject.format.replace(variablePattern, '')
              this.typeObject.values.splice(foundIndex, 1)
            }
            this.bonusPopover = null
            this.renderExample()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loadingVariable = false))
    },
    showPopover(item) {
      this.bonusInputValue = item.value
      this.bonusPopover = item.id
    },
    addNewVariable() {
      // when pressing "enter" this method runs so I added this bit here
      if (this.bonusPopover && this.bonusPopover !== 0) {
        this.updateBonusVariable(this.typeObject.values.find((item) => item.id === this.bonusPopover))
        return
      }

      this.loadingVariable = true
      let typeValue = {}
      typeValue[`${this.model}_type_id`] = this.typeObject.id
      const payload = {
        ...typeValue,
        name: this.bonusInputName,
        value: this.bonusInputValue,
      }
      service
        .postTypeValue(this.getParentId(), payload)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Variable Added')
            this.typeObject.values.push({ ...payload, ...response.data.result })
            this.bonusPopover = null
            this.bonusInputName = ''
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loadingVariable = false))
    },
    submit() {
      this.loading = true
      service
        .updateType(this.getParentId(), this.typeObject)
        .then((response) => {
          if (response.data.messages) {
            this.showErrorMessages(response.data.messages)
          } else {
            this.showSuccessMessage('Bonus updated')

            if (this.bonusType === 'toplist') {
              this.$emit('rerender', response.data.result, this.operatorId)
            }
            this.close()
          }
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loading = false))
    },
    fetchTypes(openTypeId = false, typeName = null, autoCreate = false) {
      this.loading = true

      service
        .fetchTypes(this.$route.params.bonusId, {
          name: typeName,
          with: 'restricted_countries,allowed_games',
        })
        .then(async (response) => {
          if (response.data.result.length === 0 && autoCreate) {
            const responseTypeForm = await service.postType(this.$route.params.bonusId, { name: typeName })
            response.data.result.push(responseTypeForm.data.result)

            this.$emit('rerender', responseTypeForm.data.result[0], this.operatorId)
          }
          const typeIndex = this.findTypeIndex(openTypeId, response.data.result)
          this.types = response.data.result
          this.setTypeObject(Object.assign({}, this.types[typeIndex]))
          this.$nextTick(() => {
            if (this.$refs.content) {
              this.$refs.content.getValues(this.typeObject.id)
            }
          })
          this.reRenderMultiSelect += 1
          this.reRender += 1

          this.typeId = this.typeObject.id
          this.renderExample()
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loading = false))
    },
    fetchType(bonusTypeId) {
      this.loading = true

      service
        .fetchType(this.getParentId(), bonusTypeId, { with: 'restricted_countries,allowed_games' })
        .then((response) => {
          this.setTypeObject(response.data.result[0])
          if (this.model === 'affiliate') {
            this.typeObject.restricted_countries = this.typeObject.restricted_countries.map(
              (restricted_country) => restricted_country.id
            )
          }
          this.$nextTick(() => {
            if (this.$refs.bonusType) {
              this.$refs.bonusTypes.setSelected(this.typeObject.one_liner_types)
            }

            if (this.$refs.content) {
              this.$refs.content.getValues(this.typeObject.id)
            }
          })
          this.typeObjectId = this.typeObject.id
          this.renderExample()
          this.reRenderMultiSelect += 1
          this.rerenderBonusTypes += 1
          this.reRender += 1
        })
        .catch(this.showUnknownErrorMessage)
        .finally(() => (this.loading = false))
    },
    setTypeObject(value) {
      this.typeObject = value
      if (this.model === 'affiliate') {
        this.typeObject.allowed_games = value.allowed_games.map((allowed_game) => allowed_game.game_id)
      }
    },
    addValueToFormat(value) {
      if (this.typeObject.format && this.typeObject.format.length > 0) {
        const position = this.$refs.textArea.selectionStart
        this.typeObject.format = [
          this.typeObject.format.slice(0, position),
          `[${value}]`,
          this.typeObject.format.slice(position),
        ].join('')
      } else {
        this.typeObject.format = `[${value}]`
      }
      this.$refs.textArea.focus()
      this.renderExample()
    },
    renderExample() {
      if (this.typeObject.format) {
        let example = this.typeObject.format
        _.each(this.typeObject.values, (value) => {
          example = example.split(`[${value.name}]`).join(`<code>${value.value}</code>`)
        })
        this.example = example
      } else {
        this.example = ''
      }
    },
    findTypeIndex(typeId, types) {
      if (!typeId) {
        return 0
      }
      return types.findIndex((elem) => {
        return elem.id == typeId
      })
    },
    getParentId() {
      return this.typeObject[this.model === 'affiliate' ? 'affiliate_operator_id' : 'bonus_id']
    },
    show(parentId, affiliateId, typeId = false) {
      this.$store.commit('tiptap/setAffiliateOperatorId', parentId)
      this.parentId = parentId
      this.affiliateId = affiliateId
      this.fetchTypes(typeId)
      this.isActive = true
    },
    async showOneLinerToplist(operatorId, affiliateId, marketId, gameType, type) {
      // fetch site data, see if exists
      this.operatorId = operatorId
      service
        .get({
          operator_id: operatorId,
          affiliate_id: affiliateId,
          market_id: marketId,
          game_type: gameType,
        })
        .then(async (response) => {
          let siteData = null
          if (response.data.result.length === 0) {
            // no site data yet
            // create it
            const siteDataResponse = await service.post({
              operator_id: operatorId,
              affiliate_id: affiliateId,
              market_id: marketId,
              type: gameType,
              rating: 4,
            })

            siteData = siteDataResponse.data.result
          } else {
            // fetch the first (should be only one)
            siteData = response.data.result[0]
          }

          if (siteData) {
            this.parentId = siteData.id
            this.affiliateId = siteData.affiliate_id
            // fetch type id from type name
            this.fetchTypes(false, type, true)

            this.isActive = true
            this.$nextTick(() => {
              setTimeout(() => {
                this.$refs.modal.classList.add('show')
              }, 100)
            })
          }
        })
        .catch(this.showUnknownErrorMessage)
    },
    setInitObject() {
      if (!Object.keys(this.initialTypeObject).length) {
        Object.assign(this.initialTypeObject, this.typeObject)
      }
    },
    handleFormDirty() {
      if (this.areObjectsDifferent(this.initialTypeObject, this.typeObject, 'edit')) {
        if (confirm('You have unsaved changes. Please save or cancel the changes before closing the form.')) {
          this.close()
        } else {
          return
        }
      }
      this.close()
    },
    close() {
      if (this.$refs && typeof this.$refs.modal !== 'undefined') {
        this.$refs.modal && this.$refs.modal.classList.remove('show')
        setTimeout(() => {
          Object.assign(this.$data, this.$options.data())
          this.isActive = false
        }, 100)
      }
    },
    async remove() {
      if (await confirmDelete('Warning', 'Are you sure you want to delete this item?')) {
        service
          .deleteType(this.parentId, this.typeId)
          .then((response) => {
            if (response.data.messages) {
              this.showErrorMessages(response.data.messages)
            } else {
              this.fetchTypes()
              this.showSuccessMessage('Bonus removed')

              if (this.bonusType === 'toplist') {
                this.$emit('rerender', response.data.result, this.operatorId)
                this.close()
              }
            }
          })
          .catch(this.showUnknownErrorMessage)
          .finally(() => (this.loading = false))
      }
    },
    syncValues(field, value, key = 'id') {
      if (value) {
        if (key === 'tag-input') {
          // one item (list-one)
          this.typeObject[field] = value
        } else if (Array.isArray(value)) {
          this.typeObject[field] = value.map((item) => item[key])
        } else if (_.isObject(value)) {
          // one item (list-one)
          this.typeObject[field] = value[key]
        } else {
          this.typeObject[field] = value
        }
      } else if (value === null) {
        // this is used for removing image from dropzone, this nulls the logo_field_id
        this.typeObject[field] = null
      }
    },
  },
}
</script>

<style lang="scss">
.bonus-badge:not(.btn-info) {
  color: #47596d;
  span:not(:last-child),
  .show-popover-btn {
    text-align: center;
    font-size: 13px;
    &:hover {
      // i don't know about this hover color but it's what was asked for in the task
      color: rgb(0, 55, 255);
    }
  }
  .bonus-popover {
    z-index: 9999;
    top: 115%;
    width: 215px;
    left: -30px;
  }
}

.btn-info {
  font-size: 13px;
  .bonus-popover {
    z-index: 9999;
    top: 115%;
    width: 215px;
    left: -30px;
  }
}

.bonus-preview {
  background-color: #f1f2f9;
  border-color: #f1f2f9;
  border-radius: 4px;
  padding: 10px 12px;
  margin-bottom: 10px;
  .btn-sm {
    margin-right: 10px !important;
  }
}
#one_liner_types {
  .form-control-multi-select {
    padding: 12px 12px !important;

    .copy-btn {
      margin-top: 0.5rem;
    }
  }
}

.disable-preview {
  box-shadow: none !important;
  cursor: text;
  background-color: #dddee6 !important;
}
.gap-3 {
  gap: 16px;
}
</style>
