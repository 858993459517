export default {
  getForm() {
    return [
      {
        name: 'label',
        required: true,
        display_name: 'Label',
        type: 'text',
        slug_field: 'short_code',
      },
      {
        name: 'short_code',
        required: true,
        display_name: 'Short Code',
        type: 'slug',
        disabled: true,
      },
      {
        name: 'operator_type',
        search_url: 'hercules/operators/game-types',
        required: true,
        display_name: 'Type',
        type: 'list-one',
        selectLabel: 'name',
        value_key: 'type',
      },
      {
        name: 'affiliate_id',
        search_url: 'hercules/operators/affiliates',
        required: true,
        tooltip: 'When you change site tracker list will be changed.',
        display_name: 'Site',
        type: 'list-one',
        selectLabel: 'website',
        notify_fields: [
          { field: 'tracker', param: 'affiliate_id', default: ['main'] },
          { field: 'one_liner', param: 'affiliate_id', default: ['main'] },
          { field: 'prefilled_template_id', param: 'affiliate_id', default: ['main'] },
        ],
        value_key: 'site_name',
      },
      {
        name: 'market_id',
        search_url: 'hercules/globals/markets',
        required: true,
        display_name: 'Market',
        value_key: 'market.label',
        selectLabel: 'label',
        type: 'list-one',
        country_flag: 'country_code',
      },
      {
        name: 'tracker',
        search_url: 'hercules/operators/affiliate-links/titles',
        required: true,
        display_name: 'Tracker Name',
        selectLabel: 'name',
        type: 'list-one',
        value_key: 'tracker',
        trackBy: 'name',
        fetch_on_load: false,
        notified_by: { field: 'affiliate_id', label: 'Please select a site first.' },
      },
      {
        name: 'one_liner',
        search_url: 'hercules/operators/affiliate-operator-bonuses/one-liners',
        required: true,
        display_name: 'One Liner (Bonus)',
        selectLabel: 'name',
        type: 'list-one',
        value_key: 'one_liner',
        trackBy: 'name',
        fetch_on_load: false,
        notified_by: { field: 'affiliate_id', label: 'Please select a site first.' },
      },
      {
        name: 'category_id',
        search_url: 'hercules/toplists/categories',
        required: true,
        display_name: 'Category',
        display_field: 'name',
        type: 'list-one',
        value_key: 'category_id',
        selectLabel: 'name',
        create_defaults: {
          id: 1,
          name: 'Organic',
        },
      },
      {
        name: 'prefilled_template_id',
        search_url: 'hercules/toplists/lists?list_type=prefilled_template',
        required: false,
        display_name: 'Prefilled Toplist',
        display_field: 'label',
        type: 'list-one',
        value_key: 'prefilled_template_id',
        selectLabel: 'label',
        fetch_on_load: false,
        notified_by: { field: 'affiliate_id', label: 'Please select a site first.' },
        help_text: 'Saving a prefilled toplist will override the current toplist.',
      },
      {
        name: 'protected',
        required: false,
        display_name: 'Protected',
        type: 'checkbox',
      },
      {
        name: 'enable_selling_points',
        required: false,
        display_name: 'Enable toplist-specific selling points',
        type: 'checkbox',
      },
      {
        name: 'is_paid',
        required: false,
        display_name: 'Toplist has paid positions',
        type: 'checkbox',
      },
    ]
  },
  getConfig() {
    return {
      url: 'toplists/lists',
      name: 'List',
      pagination: true,
    }
  },
  getStructure() {
    return {
      list: this.getForm(),
      config: this.getConfig(),
    }
  },
}
