<template>
  <div>
    <page-edit-module-field
      v-for="(moduleField, index) in pageModule.module_value_fields"
      :hidden="isFieldHidden(moduleField.field.name)"
      v-bind:key="index"
      :index="index"
      :siteId="siteId"
      :affiliateId="affiliateId"
      :marketId="marketId"
      :moduleField="moduleField"
    />
  </div>
</template>

<script>
import PageEditModuleField from '@templates/forms/module-dynamic-fields.vue'

export default {
  components: { PageEditModuleField },
  props: {
    siteId: {},
    affiliateId: {},
    marketId: {},
    pageModule: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    findFieldByName(name) {
      return this.pageModule.module_value_fields.find((item) => item.field.name === name)
    },
    isFieldHidden(field) {
      const showMoreContentEnabled = this.findFieldByName('show_more_content_enabled')

      if (
        (!showMoreContentEnabled ||
          showMoreContentEnabled.value == 0 ||
          showMoreContentEnabled.value === null) && 
        (field === 'label_for_show_more' || field === 'show_more_content' || field === 'label_for_show_less')
      ) {
        return true
      }

      return false
    },
  },
}
</script>
