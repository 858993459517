<template>
  <div class="row">
    <div class="card">
      <div class="card-body">
        <h2 class="mb-4">Generate postbacks</h2>
        <base-radio
          :data="platforms"
          v-model="platform"
          name="Platform"
          :inlineLayout="true"
          :useNameValue="false"
        />
        <base-input
          v-model="businessId"
          name="businessId"
          label="Business ID"
          :required="true"
          :disabled="platform === 'myaffiliates'"
        />
        <div v-if="urls">
          <div v-for="(url, action) in urls" :key="action" class="mb-4 border p-2">
            <div class="d-flex">
              <h4 class="text-uppercase">{{ action }}</h4>
              <span class="text-muted ml-3">{{ getActionLabel(action) }}</span>
            </div>
            <div class="d-flex align-items-center">
              <div @click="copyToClipboard(url)">
                <i
                  class="uil uil-clipboard clickable text-black-50"
                ></i>
              </div>
              <div class="url-copy ml-3 clickable" @click="copyToClipboard(url)">
                {{ url }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadio from '@atoms/fields/base-radio.vue'
import BaseInput from '@atoms/fields/base-input.vue'

export default {
  components: { BaseRadio, BaseInput },
  created() {
    this.setTitle()
  },
  data() {
    return {
      businessId: '1956',
      platform: 'softswiss',
      platforms: ['softswiss', 'myaffiliates']
    }
  },
  methods: {
    copyToClipboard(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.showSuccessMessage('Copied to your clipboard.')
        })
        .catch((err) => {
          this.showErrorMessage('Failed to copy text: ', err)
        })
    },
    formatSoftswissVariable(key) {
      return `${'$'}{${key}}`
    },
    formatMyAffiliatesVariable(key) {
      return `{${key}}`
    },
    formatVariable(value) {
      if (this.platform === 'softswiss') {
        return this.formatSoftswissVariable(value)
      } else if (this.platform === 'myaffiliates') {
        return this.formatMyAffiliatesVariable(value)
      }
      return value
    },
    getActionLabel(action) {
      const actionMap = {
        nrc: 'New registered customer',
        ftd: 'First time deposit',
        cpa: 'Cost per acquisition',
        deposit: 'Deposit',
        ngr: 'Net Gaming Revenue'
      }
      return actionMap[action] || action
    },
    getPlatformActions() {
      return ['nrc', 'ftd', 'cpa', 'deposit', 'ngr']
    },
    getPlatformParams() {
      const paramsMap = {
        myaffiliates: [
          'customer.id',
          'payload',
          's2s.req_id',
          's2s.pub_id',
          'media.id',
          'media.name',
          'landing_page.id',
          'landing_page.name',
          'campaign.id',
          'campaign.name',
          'brand.name'
        ],
        softswiss: [
          'campaign_id',
          'promo_id',
          'visit_id',
          'amount',
          'clickID',
          'amount_cents',
          'currency',
          'deposit_id',
          'player_id'
        ]
      }
      return paramsMap[this.platform] || []
    },
    copied() {
      this.showSuccessMessage('Copied to clipboard')
    }
  },
  computed: {
    urls() {
      const paramsForPlatform = this.getPlatformParams()

      if (paramsForPlatform.length === 0) {
        return []
      }

      let result = {}

      const baseUrl = `https://public-proxy.alphastuff.tech/tracking/v0.1/postbacks/${this.platform}/`

      this.getPlatformActions().forEach(action => {
        const myUrl = new URL(`${baseUrl}${action}`)
        paramsForPlatform.forEach(param => {
          myUrl.searchParams.append(param, this.formatVariable(param))
        })
        if (this.businessId) {
          myUrl.searchParams.append('BID', this.businessId)
        }

        result[action] = myUrl.href
          .replaceAll('%7B', '{')
          .replaceAll('%7D', '}')
          .replaceAll('%24', '$') // replace encoded curly braces
      })
      return result
    }
  }
}
</script>

<style>
.url-copy {
  word-break: break-all;
  &:hover {
    color: #007bff;
  }
}
</style>
