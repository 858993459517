<template>
  <data-table
    service-file="AffiliateOperatorsService"
    model="AffiliateOperator"
    :path="path()"
    ref="list"
    :columns="columns()"
    :showEditModal="false"
    :customRowClick="rowClick"
    :showCreate="false"
    :column-templates="columnTemplates()"
    @bulkClickByIds="bulkClick"
    :enable-bulk-select="true"
  />
  <AffiliateOperatorsEditBulk
      v-if="showBulkEdit"
      ref="bulkEdit"
      @refresh="$refs.list && $refs.list.search()"
      @closed="showBulkEdit = false"
      :textInfo="textInfo"
    >
    </AffiliateOperatorsEditBulk>
</template>

<script>
import TableColumnTemplate from '@constants/table-column-template.js'
import AffiliateOperatorsEditBulk from '@molecules/dms/operator/sites-data/edit-bulk.vue'

export default {
  data() {
    return {
      showBulkEdit: false,
      textInfo: 'Bulk updating many operator sites data at the same time can take up to 10 seconds to load'
    }
  },
  created() {
    this.setTitle(
      `${
        this.$store.state.editPage.model.website ? this.$store.state.editPage.model.website + ':' : ''
      } Operators`
    )
  },
  components: {
    AffiliateOperatorsEditBulk
  },  
  methods: {
    columns() {
      return [
        { field: 'operator_name', type: 'text', filterable: true },
        { field: 'market_label', type: 'text', filterable: true, template: TableColumnTemplate.MARKET_LABEL },
        { field: 'type', type: 'text', filterable: true },
        {
          field: 'status',
          type: 'text',
          advancedFilter: true,
          showModalAdvanced: false,
          filterable: true,
          customOptions: [
            { label: 'All', id: null },
            { label: 'Active', id: 'active' },
            { label: 'Inactive', id: 'inactive' },
            { label: 'Restricted', id: 'restricted' },
            { label: 'Coming soon', id: 'coming_soon' },
            { label: 'Blacklisted', id: 'blacklisted' },
            { label: 'Not recommended', id: 'not_recommended' },
          ],
          basicFilterType: 'select',
          optionLabelKey: 'label',
          template: TableColumnTemplate.OPERATOR_STATUS,
        },
        { field: 'updated_at', type: 'text', filterable: true, template: TableColumnTemplate.RELATIVE_DATE },
      ]
    },
    rowClick(event, row) {
      const ctrlOrMetaKey = event.ctrlKey || event.metaKey
      const path = `/operators/${row.operator_id}/sites-data?site=${this.$store.state.editPage.model.website}`
      if (ctrlOrMetaKey) {
        // open in new tab
        window.open(path, '_blank')
      } else {
        // navigate to edit page
        this.$router.push(path)
      }
    },
    path() {
      return `hercules/operators/affiliate-operators?affiliate_id=${this.$route.params['id']}&with=operator,market,linked_group&exact_match=status`
    },
    columnTemplates() {
      return Object.assign({
        operator_name: function (row) {
          return row.operator.name
        },
        status: function (row) {
          return row.status
        },
      })
    },
    bulkClick(ids) {
      this.showBulkEdit = true
      this.$nextTick(() => {
        this.$refs.bulkEdit.show(ids)
      })
    },
  },
}
</script>
