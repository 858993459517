const pagesStructure = [
  {
    id: 1,
    name: 'DMS',
    permission: null,
    children: [
      {
        id: 101,
        name: 'Operators',
        to: '/operators',
        endpoint: 'operators/logs',
        permission: 'view.operators/operators',
        customModelOptions: [
          { label: 'Extra field', id: 'AffiliateExtraField' },
          { label: 'Operator extra field value', id: 'AffiliateExtraFieldValue' },
          { label: 'Tracking link', id: 'AffiliateLink' },
          { label: 'Website markets', id: 'AffiliateMarketData' },
          { label: 'Operator sites data', id: 'AffiliateOperator' },
          { label: 'Operator sites data bonus', id: 'AffiliateType' },
          { label: 'Operator sites data bonus value', id: 'AffiliateTypeValue' },
          { label: 'Market data', id: 'Bonus' },
          { label: 'Markets data bonus', id: 'BonusType' },
          { label: 'Markets data bonus value', id: 'BonusTypeValue' },
          { label: 'Operator', id: 'Operator' },
          { label: 'Odds Types', id: 'OddsType' },
          { label: 'Operator Types', id: 'CasinoGameType' },
        ],
      },
      {
        id: 102,
        name: 'Toplists',
        to: '/toplists',
        endpoint: 'toplists/logs',
        permission: 'view.toplists/lists',
        customModelOptions: [
          { label: 'Toplist', id: 'TopList' },
          { label: 'Toplist operators', id: 'ListItem' },
        ],
      },
      {
        id: 103,
        name: 'Prefilled Toplists',
        to: '/prefilled-toplists',
        endpoint: 'toplists/logs',
        permission: 'view.toplists/lists',
        customModelOptions: [
          { label: 'Toplist', id: 'TopList' },
          { label: 'Toplist operators', id: 'ListItem' },
        ],
      },
      {
        id: 104,
        name: 'Tracking links',
        to: '/tracking-links',
        endpoint: 'operators/logs?subject_type=AffiliateLink',
        permission: 'view.operators/affiliate-links',
        customModelOptions: [{ label: 'Tracking link', id: 'AffiliateLink' }],
      },
      {
        id: 105,
        name: 'Games',
        to: '/games',
        endpoint: 'games/logs',
        permission: 'view.games/games',
        customModelOptions: [
          { label: 'Games sites data', id: 'AffiliateGame' },
          { label: 'Game extra field value', id: 'AffiliateGameExtraFieldValue' },
          { label: 'Game', id: 'Game' },
          { label: 'Game markets data', id: 'GameMarket' },
          { label: 'Game screenshot', id: 'GameScreenshot' },
          { label: 'Game symbols', id: 'GameSymbol' },
          { label: 'Game Extra Fields', id: 'ExtraField' },
        ],
      },
      {
        id: 106,
        name: 'Crypto exchanges',
        to: '/crypto-exchanges',
        endpoint: 'cryptos/logs',
        permission: 'view.cryptos/exchanges',
        customModelOptions: [{ label: 'Crypto Exchanges', id: 'Exchange' }],
      },
      {
        id: 107,
        name: 'Crypto wallets',
        to: '/crypto-wallets',
        endpoint: 'cryptos/logs',
        permission: 'view.cryptos/wallets',
        customModelOptions: [{ label: 'Crypto Wallets', id: 'Wallet' }],
      },
      {
        id: 108,
        name: 'Crypto brokers',
        to: '/crypto-brokers',
        endpoint: 'cryptos/logs',
        permission: 'view.cryptos/brokers',
        customModelOptions: [{ label: 'Crypto Brokers', id: 'Broker' }],
      },
      {
        id: 109,
        name: 'Translations',
        to: '/translations',
        endpoint: 'translations/logs',
        permission: 'view.translations/translations',
        customModelOptions: [{ label: 'Translation', id: 'Translation' }],
      },
      {
        id: 110,
        name: 'Affiliate Site Settings',
        to: '/affiliates',
        endpoint: 'operators/logs',
        permission: 'view.operators/affiliates',
        customModelOptions: [{ label: 'DMS website', id: 'Affiliate' }],
      },
      {
        id: 111,
        name: 'Ribbons',
        endpoint: 'globals/logs',
        permission: 'view.globals/ribbons',
        customModelOptions: [{ label: 'Ribbon', id: 'BaseRibbon' }],
      },
    ],
  },
  {
    id: 2,
    name: 'CMS',
    permission: null,
    children: [
      {
        id: 201,
        name: 'Pages',
        to: '/site-pages',
        endpoint: 'sites/logs',
        permission: 'view.sites/pages',
        customModelOptions: [
          { label: 'Page', id: 'Page' },
          { label: 'Page custom hreflang', id: 'PageCustomHreflang' },
          { label: 'Hreflang group', id: 'PageGroup' },
          { label: 'Page hreflang group', id: 'PageHreflangGroup' },
          { label: 'Page module', id: 'PageModule' },
          { label: 'Page module field', id: 'PageModuleField' },
          { label: 'Page style', id: 'PageStyle' },
          { label: 'Page vote', id: 'PageVote' },
          { label: 'Page extra field', id: 'ExtraField' },
          { label: 'Page extra field value', id: 'ExtraFieldValue' },
          { label: 'Page raw javascript', id: 'JsonSchema' },
          { label: 'Page category', id: 'Category' },
        ],
      },
      {
        id: 202,
        name: 'Menus',
        to: '/site-menus',
        endpoint: 'sites/logs',
        permission: 'view.sites/menus',
        customModelOptions: [
          { label: 'Menu', id: 'Menu' },
          { label: 'Menu item', id: 'MenuItem' },
        ],
      },
      {
        id: 203,
        name: 'Banners',
        to: '/site-banners',
        endpoint: 'sites/logs',
        permission: 'view.sites/banners',
        customModelOptions: [
          { label: 'Banner', id: 'Banner' },
          { label: 'Banner item', id: 'BannerItem' },
        ],
      },
      {
        id: 204,
        name: 'Authors',
        to: '/site-authors',
        endpoint: 'sites/logs',
        permission: 'view.sites/authors',
        customModelOptions: [
          { label: 'Author', id: 'Author' },
          { label: 'Knows about', id: 'KnowsAbout' },
          { label: 'Knows about links', id: 'KnowsAboutLink' },
        ],
      },
      {
        id: 205,
        name: 'User Comments',
        to: '/user-comments',
        endpoint: 'sites/logs',
        permission: 'view.content/comments',
        customModelOptions: [],
      },
      {
        id: 206,
        name: 'Deployments',
        to: '/site-deployments',
        endpoint: 'sites/logs',
        permission: 'view.sites/deployments',
        customModelOptions: [
          { label: 'Deployment', id: 'Deployment' },
          { label: 'Deployment details', id: 'DeploymentLog' },
        ],
      },
      {
        id: 207,
        name: 'Redirects',
        to: '/site-redirects',
        endpoint: 'sites/logs',
        permission: 'view.sites/redirects',
        customModelOptions: [{ label: 'Redirect', id: 'Redirect' }],
      },
      {
        id: 208,
        name: 'Breadcrumbs',
        to: '/breadcrumbs',
        endpoint: 'sites/logs',
        permission: 'view.sites/breadcrumbs',
        customModelOptions: [{ label: 'Breadcrumb', id: 'Breadcrumb' }],
      },
      {
        id: 209,
        name: 'Schema',
        to: '/schema',
        endpoint: 'sites/logs',
        permission: 'view.sites/schema-information',
        customModelOptions: [{ label: 'Site schema', id: 'SchemaInformation' }],
      },
      {
        id: 210,
        name: 'Page Types',
        to: '/site-templates',
        endpoint: 'sites/logs',
        permission: 'view.sites/templates',
        customModelOptions: [
          { label: 'Page types', id: 'Template' },
          { label: 'Template block', id: 'TemplateBlock' },
          { label: 'Page type sections enabled', id: 'TemplateSection' },
          { label: 'Autogenerated content', id: 'AutogeneratedContent' },
          { label: 'Page style', id: 'PageStyle' },
          { label: 'Module', id: 'ModuleValue' },
          { label: 'Module field value', id: 'ModuleValueField' },
          { label: 'Page types extra fields', id: 'ExtraField' },
          { label: 'Page extra field value', id: 'ExtraFieldValue' },
        ],
      },
      {
        id: 211,
        name: 'Prefilled modules',
        to: '/site-prefilled-modules',
        endpoint: 'sites/logs',
        permission: 'view.sites/prefilled-modules',
        customModelOptions: [
          { label: 'Prefilled module', id: 'PrefilledModule' },
          { label: 'Prefilled module market', id: 'PrefilledModuleMarket' },
          { label: 'Module', id: 'ModuleValue' },
          { label: 'Module field value', id: 'ModuleValueField' },
        ],
      },
      {
        id: 212,
        name: 'Site Settings',
        to: '/site-sites',
        endpoint: 'sites/logs',
        permission: 'view.sites/sites',
        customModelOptions: [
          { label: 'Module', id: 'ModuleValue' },
          { label: 'Module field value', id: 'ModuleValueField' },
          { label: 'Page category', id: 'Category' },
          { label: 'Module style', id: 'ModuleStyle' },
          { label: 'Site', id: 'Site' },
          { label: 'Module color', id: 'Color' },
          { label: 'Page category', id: 'Category' },
        ],
      },
      {
        id: 213,
        name: 'Sections',
        to: '/site-sections',
        endpoint: 'sites/logs',
        permission: 'view.sites/sections',
        customModelOptions: [
          { label: 'Section', id: 'Section' },
          { label: 'Extra fields (in Sections)', id: 'ExtraField' },
          { label: 'Page extra field value', id: 'ExtraFieldValue' },
          { label: 'Section', id: 'Section' },
          { label: 'Section style', id: 'SectionStyle' },
        ],
      },
      {
        id: 214,
        name: 'Market sections',
        to: '/site-markets',
        endpoint: 'sites/logs',
        permission: 'view.sites/site-markets',
        customModelOptions: [
          { label: 'Module', id: 'ModuleValue' },
          { label: 'Module field value', id: 'ModuleValueField' },
          { label: 'Page extra field value', id: 'ExtraFieldValue' },
          { label: 'Prefilled module', id: 'PrefilledModule' },
          { label: 'Prefilled module market', id: 'PrefilledModuleMarket' },
          { label: 'Module color', id: 'Color' },
          { label: 'Module field', id: 'ModuleField' },
          { label: 'Module style', id: 'ModuleStyle' },
          { label: 'Page module', id: 'PageModule' },
          { label: 'Page module field', id: 'PageModuleField' },
          { label: 'Section', id: 'Section' },
          { label: 'Market section style', id: 'SiteMarketSectionStyle' },
        ],
      },
      {
        id: 215,
        name: 'Content',
        endpoint: 'sites/logs/combine-content?subject_group=Pages',
        permission: 'view.sites/sections',
        customModelOptions: [
          { label: 'Module', id: 'ModuleValue' },
          { label: 'Module field value', id: 'ModuleValueField' },
          { label: 'Page', id: 'Page' },
          { label: 'Content Value', id: 'Value' },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'AMS',
    permission: null,
    children: [
      {
        id: 301,
        name: 'Dashboard',
        to: '/koala-dashboard',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/monitoring',
        customModelOptions: [],
      },
      {
        id: 302,
        name: 'Monitoring',
        to: '/koala-monitoring',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/monitoring',
        customModelOptions: [{ label: 'Accounts Credentials', id: 'Credentials' }],
      },
      {
        id: 303,
        name: 'Grouped Monitoring',
        to: '/koala-grouped-monitoring',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/monitoring',
        customModelOptions: [{ label: 'Accounts Credentials', id: 'Credentials' }],
      },
      {
        id: 304,
        name: 'Accounts',
        to: '/koala-accounts',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/accounts',
        customModelOptions: [
          { label: 'Account', id: 'Account' },
          { label: 'Account jobs', id: 'AccountJobs' },
          { label: 'Accounts > Credentials', id: 'Credentials' },
        ],
      },
      {
        id: 305,
        name: 'Account Reports',
        to: '/koala-account-reports',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/account-reports',
        customModelOptions: [{ label: 'Account report', id: 'AccountReport' }],
      },
      {
        id: 306,
        name: 'Proxies',
        to: '/koala-proxies',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/accounts',
        customModelOptions: [{ label: 'Proxy', id: 'Proxy' }],
      },
      {
        id: 307,
        name: 'Reports',
        to: '/koala-platform-reports',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/reports',
        customModelOptions: [{ label: 'Reports', id: 'Report' }],
      },
      {
        id: 308,
        name: 'Platforms',
        to: '/koala-platforms',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/platforms',
        customModelOptions: [{ label: 'Platform', id: 'Platform' }],
      },
      {
        id: 309,
        name: 'Repull',
        to: '/koala-repull',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/account-jobs',
        customModelOptions: [{ label: 'Repull', id: 'Repull' }],
      },
      {
        id: 310,
        name: 'Operators',
        to: '/koala-operators',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/operators',
        customModelOptions: [{ label: 'Operators', id: 'Operator' }],
      },
      {
        id: 311,
        name: 'Units',
        to: '/koala-units',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/business-units',
        customModelOptions: [{ label: 'Units', id: 'BusinessUnit' }],
      },
      {
        id: 312,
        name: 'Currencies',
        to: '/koala-currencies',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/currencies',
        customModelOptions: [{ label: 'Currencies', id: 'Currency' }],
      },
      {
        id: 313,
        name: 'Campaigns',
        to: '/koala-campaigns',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/campaigns',
        customModelOptions: [{ label: 'Campaign', id: 'Campaign' }],
      },
      {
        id: 314,
        name: 'Tag Values',
        to: '/tag-values',
        endpoint: 'logs/activity-logs/koala',
        permission: 'view.koala/tag-values',
        customModelOptions: [{ label: 'Tag Values', id: 'TagValue' }],
      },
    ],
  },
  {
    id: 4,
    name: 'Sports',
    permission: null,
    children: [
      {
        id: 401,
        name: 'Site events',
        to: '/sports-site-events',
        endpoint: 'sports/logs',
        permission: 'view.sports/site-events',
        customModelOptions: [{ label: 'Sports event', id: 'Event' }],
      },
      {
        id: 402,
        name: 'Site providers',
        to: '/sports-site-providers',
        endpoint: 'sports/logs',
        permission: 'view.sports/site-providers',
        customModelOptions: [
          { label: 'Sport provider', id: 'Provider' },
          { label: 'Site provider', id: 'SiteProvider' },
          { label: 'Site provider link', id: 'SiteProviderLink' },
          { label: 'Site sport provider', id: 'SiteSportProvider' },
        ],
      },
      {
        id: 403,
        name: 'Site tournaments',
        to: '/sports-site-tournaments',
        endpoint: 'sports/logs',
        permission: 'view.sports/site-tournaments',
        customModelOptions: [{ label: 'Site tournaments', id: 'SiteTournament' }],
      },
      {
        id: 404,
        name: 'Site countries',
        to: '/sports-site-countries',
        endpoint: 'sports/logs',
        permission: 'view.sports/site-countries',
        customModelOptions: [{ label: 'Site country', id: 'SiteCountry' }],
      },
      {
        id: 405,
        name: 'Site sports',
        to: '/sports-site-sports',
        endpoint: 'sports/logs',
        permission: 'view.sports/site-sports',
        customModelOptions: [
          { label: 'Site sport', id: 'SiteSport' },
          { label: 'Sport', id: 'Sport' },
        ],
      },
      {
        id: 406,
        name: 'Events',
        to: '/sports-events',
        endpoint: 'sports/logs',
        permission: 'view.sports/events',
        customModelOptions: [{ label: 'Event', id: 'Event' }],
      },
      {
        id: 407,
        name: 'Event Mapper',
        to: '/sports-event-mapper',
        endpoint: 'sports/logs',
        permission: 'view.sports/event-streams',
        customModelOptions: [],
      },
      {
        id: 408,
        name: 'Providers',
        to: '/sports-providers',
        endpoint: 'sports/logs',
        permission: 'view.sports/providers',
        customModelOptions: [{ label: 'Sport Providers', id: 'Provider' }],
      },
      {
        id: 409,
        name: 'Tournaments',
        to: '/sports-tournaments',
        endpoint: 'sports/logs',
        permission: 'view.sports/tournaments',
        customModelOptions: [{ label: 'Tournament', id: 'Tournament' }],
      },
      {
        id: 410,
        name: 'Teams',
        to: '/sports-teams',
        endpoint: 'sports/logs',
        permission: 'view.sports/teams',
        customModelOptions: [{ label: 'Teams', id: 'Team' }],
      },
    ],
  },
  {
    id: 5,
    name: 'Widgets',
    permission: null,
    children: [
      {
        id: 501,
        name: 'Operator DMS Widgets',
        to: '/site-widgets-toplist',
        endpoint: 'widgets/logs',
        permission: 'view.widgets/toplist',
        customModelOptions: [
          { label: 'Widget Toplist', id: 'Toplist' },
          { label: 'Widget Toplist item', id: 'ToplistItem' },
          { label: 'Widgets components order', id: 'Component' },
        ],
      },
      {
        id: 502,
        name: 'Sports Widgets',
        to: '/site-widgets-odds',
        endpoint: 'widgets/logs',
        permission: 'view.widgets/odds',
        customModelOptions: [
          { label: 'Odds widget', id: 'Odds' },
          { label: 'Widgets components order', id: 'Component' },
        ],
      },
    ],
  },
  {
    name: 'General',
    class: 'setup',
    permission: null,
    id: 6,
    children: [
      {
        id: 601,
        name: 'Selling Points',
        to: '/selling-points',
        endpoint: 'globals/logs',
        permission: 'view.globals/selling-points',
        customModelOptions: [{ label: 'Selling Point', id: 'BaseSellingPoint' }],
      },
      {
        id: 602,
        name: 'Payment Methods',
        to: '/deposit-methods',
        endpoint: 'globals/logs',
        permission: 'view.globals/deposit-methods',
        customModelOptions: [{ label: 'Payment methods', id: 'DepositMethod' }],
      },
      {
        id: 603,
        name: 'Game Providers',
        to: '/providers',
        endpoint: 'globals/logs',
        permission: 'view.globals/software',
        customModelOptions: [{ label: 'Providers', id: 'Software' }],
      },
      {
        id: 604,
        name: 'Operator Licences',
        to: '/licences',
        endpoint: 'operators/logs',
        permission: 'view.operators/licenses',
        customModelOptions: [{ label: 'Operator license', id: 'License' }],
      },
      {
        id: 605,
        name: 'Crypto Licences',
        to: '/crypto-licences',
        endpoint: 'cryptos/logs',
        permission: 'view.cryptos/licences',
        customModelOptions: [{ label: 'Crypto license', id: 'Licence' }],
      },
      {
        id: 606,
        name: 'Crypto Services',
        to: '/crypto-services',
        endpoint: 'cryptos/logs',
        permission: 'view.cryptos/services',
        customModelOptions: [{ label: 'Crypto services', id: 'Service' }],
      },
      {
        id: 607,
        name: 'Support Types',
        to: '/support-types',
        endpoint: 'globals/logs',
        permission: 'view.globals/support-types',
        customModelOptions: [{ label: 'Support type', id: 'SupportType' }],
      },
      {
        id: 608,
        name: 'Game Categories',
        to: '/game-categories',
        endpoint: 'globals/logs',
        permission: 'view.globals/game-categories',
        customModelOptions: [{ label: 'Game Category', id: 'GameCategory' }],
      },
      {
        id: 609,
        name: 'Currencies',
        to: '/currencies',
        endpoint: 'globals/logs',
        permission: 'view.globals/currencies',
        customModelOptions: [{ label: 'Currency', id: 'Currency' }],
      },
      {
        id: 610,
        name: 'Countries',
        to: '/countries',
        endpoint: 'globals/logs',
        permission: 'view.globals/countries',
        customModelOptions: [{ label: 'Country', id: 'Country' }],
      },
      {
        id: 611,
        name: 'Critics',
        to: '/critics',
        endpoint: 'globals/logs',
        permission: 'view.globals/critics',
        customModelOptions: [{ label: 'Critic', id: 'Critic' }],
      },
      {
        id: 612,
        name: 'Languages',
        to: '/languages',
        endpoint: 'globals/logs',
        permission: 'view.globals/languages',
        customModelOptions: [{ label: 'Language', id: 'Language' }],
      },
      {
        id: 613,
        name: 'Markets',
        to: '/markets',
        endpoint: 'globals/logs',
        permission: 'view.globals/markets',
        customModelOptions: [{ label: 'Market', id: 'BaseMarket' }],
      },
      {
        id: 614,
        name: 'Units',
        to: '/units',
        endpoint: 'operators/logs',
        permission: 'view.operators/units',
        customModelOptions: [{ label: 'Unit', id: 'Unit' }],
      },
      {
        id: 615,
        name: 'Responsible Gamings',
        to: '/responsible-gamings',
        endpoint: 'operators/logs',
        permission: 'view.operators/responsible-gamings',
        customModelOptions: [{ label: 'Responsible gaming', id: 'ResponsibleGaming' }],
      },
      {
        id: 616,
        name: 'Gambling Companies',
        to: '/gambling-companies',
        endpoint: 'operators/logs',
        permission: 'view.operators/gambling-companies',
        customModelOptions: [{ label: 'Gambling company', id: 'GamblingCompany' }],
      },
      {
        id: 617,
        name: 'Affiliate Programs',
        to: '/affiliate-programs',
        endpoint: 'operators/logs',
        permission: 'view.operators/affiliate-programs',
        customModelOptions: [{ label: 'Affiliate program', id: 'AffiliateProgram' }],
      },
      {
        id: 618,
        name: 'Affiliate Platforms',
        to: '/affiliate-platforms',
        endpoint: 'operators/logs',
        permission: 'view.operators/affiliate-platforms',
        customModelOptions: [
          { label: 'Affiliate platform', id: 'AffiliatePlatform' },
          { label: 'Affiliate platform domains', id: 'AffiliatePlatformDomain' },
        ],
      },
      {
        id: 619,
        name: 'Sport Categories',
        to: '/sport-categories',
        endpoint: 'operators/logs',
        permission: 'view.operators/sport-categories',
        customModelOptions: [{ label: 'Sport Category', id: 'SportCategory' }],
      },
      {
        id: 620,
        name: 'Betting Types',
        to: '/betting-types',
        endpoint: 'operators/logs',
        permission: 'view.operators/betting-types',
        customModelOptions: [{ label: 'Betting Types', id: 'BettingType' }],
      },
    ],
  },
  {
    id: 7,
    name: 'DevOps',
    permission: null,
    children: [
      {
        id: 801,
        name: 'JSON Tokens',
        to: '/site-configurations',
        endpoint: 'sites/logs',
        permission: 'view.sites/configs',
        customModelOptions: [{ label: 'JSON Tokens', id: 'SiteConfig' }],
      },
    ],
  },
  {
    id: 8,
    name: 'Admin',
    class: 'admin',
    permission: null,
    children: [
      {
        id: 901,
        name: 'Users',
        to: '/users',
        endpoint: 'backoffice/logs',
        permission: 'view.backoffice/users',
        customModelOptions: [{ label: 'User', id: 'User' }],
      },
      {
        id: 902,
        name: 'Roles',
        to: '/roles',
        endpoint: 'backoffice/logs',
        permission: 'view.backoffice/roles',
        customModelOptions: [
          { label: 'Roles', id: 'Role' },
          { label: 'Permissions', id: 'Permission' },
        ],
      },
    ],
  },
  {
    id: 9,
    name: 'Assets',
    class: 'assets',
    permission: null,
    children: [
      {
        id: 901,
        name: 'Images',
        to: '/users',
        endpoint: 'assets/logs',
        permission: 'view.assets/files',
        customModelOptions: [],
      },
    ],
  },
  {
    id: 10,
    name: 'Performance Logs',
    class: 'performance-logs',
    permission: null,
    children: [
      {
        id: 1001,
        name: 'Producer Performance Logs',
        to: '/performance-logs',
        endpoint: 'logs/activity-logs/json-updater/producer-performance-logs',
        permission: 'view.backoffice/roles',
      },
      {
        id: 1002,
        name: 'Consumer Performance Logs',
        to: '/performance-logs',
        endpoint: 'logs/activity-logs/json-updater/consumer-performance-logs',
        permission: 'view.backoffice/roles',
      },
    ],
  },
]

export default pagesStructure
