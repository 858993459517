<template>
  <div class="sidebar-body-content mw-100 d-flex bg-white card">
    <div class="card-header p-2">
      <div class="w-50">
        <base-select
          label="Site"
          v-model="site"
          ref="siteSelect"
          optionLabelKey="affiliate.name"
          placeholder="Choose Site"
          :customOptions="siteOptions"
          selectLabel="affiliate.name"
          trackBy="affiliate.name"
          :searchBy="'search'"
          :clearable="true"
          class="pr-2 pl-2 mb-0"
          @update:modelValue="onFilterChange"
        />
      </div>
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :customOptions="marketOptions"
          optionLabelKey="market.label"
          v-model="market"
          label="Market"
          trackBy="market.label"
          placeholder="Select a market"
          selectLabel="market.label"
          :countryFlag="'countryFlag'"
          :clearable="true"
          :searchBy="'market.label'"
          @update:modelValue="onFilterChange"
        />
      </div>
      <div class="w-50">
        <base-select
          class="w-100 pr-2 pl-2 mb-0 bg-select"
          :customOptions="typeOptions"
          :clearable="true"
          optionLabelKey="type"
          trackBy="type"
          :searchBy="'type'"
          v-model="type"
          label="Type"
          @update:modelValue="onFilterChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, computed, watch, onMounted } from 'vue'
import BaseSelect from '@/components/fields/base-select.vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import GeneralService from '@/services/GeneralService.js'

const store = useStore()
const route = useRoute()
const isMarketPreSelected = computed(() => store.state.editPage.marketId)

const dataForSiteMarketType = ref([])
const siteOptions = ref([])
const marketOptions = ref([])
const typeOptions = ref([])

const market = ref(isMarketPreSelected.value)
const type = ref()
const site = ref()
const emit = defineEmits(['filterChange'])

const operatorId = computed(() => {
  return route.params.id
})

const onFilterChange = () => {
  emit('filterChange', market.value, type.value, site.value)
  store.commit('editPage/setMarketId', market.value || null)
}

const getSiteMarketTypeOptions = async () => {
  const path = `hercules/operators/affiliate-operators?operator_id=${operatorId.value}&with=affiliate,market,type&orderBy=name&ascending=1`
  try {
    const response = await GeneralService.fetchItems(path, {})
    dataForSiteMarketType.value = response.data.result
    processUniqueOptions()
  } catch (error) {
    console.error(error)
  }
}

const processUniqueOptions = () => {
  siteOptions.value = getUniqueOptions(dataForSiteMarketType.value, 'affiliate.name')
    .map((item) => {
      if (!item.affiliate) {
        return null
      }
      return {
        'affiliate.name': resolve('affiliate.name', item),
      }
    })
    .filter(Boolean)

  marketOptions.value = getUniqueOptions(dataForSiteMarketType.value, 'market.label').map((item) => ({
    'market.label': resolve('market.label', item),
    countryFlag: resolve('market.country_code', item),
  }))

  typeOptions.value = getUniqueOptions(dataForSiteMarketType.value, 'type').map((item) => ({
    type: item.type,
  }))
}

const resolve = (path, obj) => {
  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj)
}

const getUniqueOptions = (data, key) => {
  const uniqueValues = new Map()
  return data.filter((item) => {
    const value = resolve(key, item)
    if (!uniqueValues.has(value)) {
      uniqueValues.set(value, true)
      return true
    }
    return false
  })
}

watch([market, type, site], () => {
  onFilterChange()
})

onMounted(() => {
  getSiteMarketTypeOptions()
})
</script>
<style scoped>
.rounded-md {
  border-radius: 8px;
}
</style>
