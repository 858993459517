import Api from './Api'

export default {
  get(data) {
    return Api().get(`hercules/operators/affiliates/field-management`, { params: data })
  },
  update(id, data) {
    return Api().put(`hercules/operators/affiliates/field-management/${id}`, data)
  },
}
