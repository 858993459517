<template>
  <div class="additional mb-2" v-if="operatorData?.affiliate?.id || operatorData?.market?.id">
    <span>Last updated: {{ $dateRelative(operator.updated_at) }}</span>
    <router-link
      tag="span"
      class="badge bg-azure ml-2 clickable"
      :to="{ path: `/affiliates/${operatorData?.affiliate?.id}` }"
    >
      {{ operatorData?.affiliate?.name }}
    </router-link>
    <router-link
      tag="span"
      class="badge bg-orange ml-2 clickable"
      :to="{ path: '/markets', query: { id: operatorData?.market?.id } }"
    >
      {{ operatorData?.market?.label }}
    </router-link>
    <span class="badge bg-purple ml-2">{{ operatorData?.type }}</span>
  </div>
  <div class="additional mb-2" v-else>
    <span>Last updated: {{ $dateRelative(operator.updated_at) }}</span>
    <router-link
      tag="span"
      class="badge bg-azure ml-2 clickable"
      :to="{ path: `/affiliates/${bonusesAndLinks?.affiliate?.id}` }"
    >
      {{ bonusesAndLinks?.affiliate?.name }}
    </router-link>
    <router-link
      tag="span"
      class="badge bg-orange ml-2 clickable"
      :to="{ path: '/markets', query: { id: bonusesAndLinks?.market?.id } }"
    >
      {{ bonusesAndLinks?.market?.label }}
    </router-link>
    <span class="badge bg-purple ml-2">{{ bonusesAndLinks?.type }}</span>
  </div>
</template>
<script setup>
import { ref, defineProps, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

const props = defineProps({
  operator: { type: Object, required: true },
})
const store = useStore()
const route = useRoute()
const operatorData = computed(() => ({ ...store.state.dynamicForm.formObject }))
const bonusesAndLinksId = ref()

const bonusesAndLinks = computed(() => {
  if (store.state.editPage.extraOperatorInfoId === bonusesAndLinksId.value) {
    return store.state.editPage.extraOperatorInfo
  } else {
    return null
  }
})

const getSitesData = (id) => {
  if (bonusesAndLinks.value) {
    return
  }

  AffiliateOperatorsService.getOne(id, {
    with: 'affiliate,market',
  }).then((response) => {
    const res = response.data.result[0]

    store.commit('editPage/setExtraOperatorInfo', {
      id: id,
      data: {
        affiliate_id: res.affiliate_id,
        affiliate: res.affiliate,
        market: res.market,
        type: res.type,
      },
    })
  })
}

watch(
  () => route.params,
  (newParams) => {
    if (newParams.bonusId) {
      bonusesAndLinksId.value = newParams.bonusId
    } else if (newParams.linkId) {
      bonusesAndLinksId.value = newParams.linkId
    } else {
      bonusesAndLinksId.value = null
    }
  },
  { immediate: true }
)

watch(
  () => bonusesAndLinksId.value,
  (newVal) => {
    if (newVal) {
      getSitesData(newVal)
    } else {
      // Clear the stored data if bonusesAndLinksId is null
      store.commit('editPage/setExtraOperatorInfo', { id: null, data: null })
    }
  },
  { immediate: true }
)
</script>
