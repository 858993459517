<template>
  <div class="items-container">
    <div class="card">
      <div class="card-header border-bottom d-flex justify-content-between py-3">
        <div class="d-flex align-items-center">
          <h3 class="mb-0">{{ label }}</h3>
        </div>

        <div class="d-flex align-items-center">
          <button v-if="!isPrefilled" class="btn btn-sm btn-secondary mr-0" @click="showAddModalAction">
            <i class="uil uil-plus" />
            Add operator
          </button>

          <div class="d-flex align-items-center ml-3" v-if="list && scheduledList">
            <span class="text-muted d-none d-xl-block">Schedule</span>

            <base-date
              wrapperClass="toplist-schedule-date-picker"
              class="pl-3"
              :label="null"
              v-model="list.scheduled_at"
              :clearable="false"
              :config="getDatePickerConfig()"
            />
          </div>
        </div>
      </div>

      <div class="card-body" :class="{ 'overflow-x-auto': schedulingActive }">
        <div
          v-if="isLoading === false && items.length === 0"
          class="d-flex flex-column align-items-center mt-3"
        >
          <h2>List is empty</h2>

          <div class="d-flex my-2">
            <i class="uil uil-info-circle mr-2" />
            An empty list will
            <strong class="mx-1">never</strong>
            overwrite the current list
          </div>

          <div class="d-flex flex-gutter">
            <button class="btn btn-secondary mt-3" @click="showAddModalAction">
              <i class="uil uil-plus" />
              Add operators
            </button>

            <button v-if="scheduledList" class="btn btn-secondary mt-3" @click="copyCurrentToplist">
              <i class="uil uil-copy" />
              Copy current toplist
            </button>
          </div>
        </div>

        <div v-else class="">
          <div class="flex-table-row text-muted text-uppercase font-size-sm align-items-center">
            <div class="flex-table-cell toplist-item-position" v-if="!isPrefilled">Position</div>

            <div class="flex-table-cell toplist-item-operator-name">Operator</div>

            <div class="flex-table-cell toplist-item-rating" v-if="!isPrefilledTemplate && !isPrefilled">
              Rating
            </div>

            <div class="flex-table-cell toplist-item-bonus-tracker" v-if="!isPrefilledTemplate">
              <div class="flex-table-cell toplist-item-bonus">
                Bonus
                <div class="toplist-item-bonus-tracker-actions align-items-center">
                  <span class="ml-2 badge font-size-xs bg-white-10 text-truncate">{{ list.one_liner }}</span>

                  <i
                    class="uil ml-2 text-muted clickable"
                    :class="[showFullBonus ? ['uil-eye-slash'] : ['uil-eye']]"
                    @click="() => (showFullBonus = !showFullBonus)"
                  />
                </div>
              </div>

              <div class="flex-table-cell toplist-item-tracker">
                Tracker
                <div class="toplist-item-bonus-tracker-actions align-items-center">
                  <span class="ml-2 badge bg-white-10 font-size-xs text-truncate">{{ list.tracker }}</span>

                  <i
                    class="uil ml-2 text-muted clickable"
                    :class="[showFullTracker ? ['uil-eye-slash'] : ['uil-eye']]"
                    @click="() => (showFullTracker = !showFullTracker)"
                  />
                </div>
              </div>
            </div>
            <div class="flex-table-cell toplist-item-affiliate-platform" v-if="showAffiliatePlatform">
              Platform
              <i
                class="uil ml-2 text-muted clickable"
                :class="[showFullAffiliatePlatform ? ['uil-eye-slash'] : ['uil-eye']]"
                @click="() => (showFullAffiliatePlatform = !showFullAffiliatePlatform)"
              />
            </div>
            <div class="flex-table-cell toplist-item-ribbons" v-if="!isPrefilledTemplate">Ribbons</div>
            <div class="flex-table-cell toplist-item-ribbons" v-if="!isPrefilledTemplate">Bonus Type</div>
            <div
              class="flex-table-cell toplist-item-selling-points"
              v-if="!isPrefilledTemplate && list.enable_selling_points"
            >
              Selling points
            </div>

            <div class="toplist-item-actions" v-if="!isPrefilled">Actions</div>
          </div>

          <draggable
            v-if="!isPrefilled"
            v-model="items"
            element="div"
            class="flex-table flex-table-body overflow-x-scroll"
            item-key="id"
          >
            <template #item="{ element, index }">
              <item-row
                :row="element"
                :realIndex="realIndexes[index]"
                :index="index"
                :list="list"
                :storeName="storeName"
                @openPositionModal="openPositionModal"
                @updateTracker="updateTracker"
                @updateBonus="updateBonus"
                @domainUpdated="domainUpdated"
                @bonusTypeUpdated="bonusTypeUpdated"
                :showAffiliatePlatform="showAffiliatePlatform"
                :showFullAffiliatePlatform="showFullAffiliatePlatform"
                :showFullBonus="showFullBonus"
                :showFullTracker="showFullTracker"
              />
            </template>
          </draggable>

          <span v-if="isPrefilled">
            <item-row
              v-for="(item, index) in items"
              v-bind:key="item.id"
              :row="item"
              :realIndex="realIndexes[index]"
              :index="index"
              :list="list"
              :storeName="storeName"
              @openPositionModal="openPositionModal"
              @updateTracker="updateTracker"
              @updateBonus="updateBonus"
              :showFullBonus="showFullBonus"
              :showFullTracker="showFullTracker"
              :can-change-position="false"
            />
          </span>
        </div>
      </div>

      <div class="card-footer">
        <button
          class="btn btn-secondary btn-sm icon-btn"
          @click="exportCsv"
          :disabled="items.length === 0"
          v-if="!isPrefilledTemplate"
        >
          <i class="uil uil-file-export"></i>
          Export to CSV
        </button>
      </div>
    </div>

    <add-modal
      v-if="showAddModal"
      ref="addModal"
      :items="items"
      :toplist="list"
      @done="done"
      :lowestPosition="notRemovedItems.length"
    />

    <item-position-modal
      ref="positionModal"
      @done="done"
      :lowestPosition="items.length"
      :storeName="storeName"
    />

    <affiliate-bonus-edit v-if="showBonusEditModal" ref="editBonusModal" @refresh="bonusUpdated" />

    <affiliate-link-edit
      v-if="showLinkEditModal"
      ref="editLinkModal"
      :closeAfterRemove="true"
      :showDelete="false"
      @refresh="trackerUpdated"
    />
  </div>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import ItemRow from '@organisms/dms/toplist/item-row.vue'
import ItemPositionModal from '@organisms/dms/toplist/item-position-modal.vue'
import AffiliateLinkEdit from '@molecules/dms/affiliate-link/edit-modal.vue'
import AffiliateBonusEdit from '@organisms/dms/toplist/edit-bonus-modal.vue'
import AffiliatePlatformDomainModal from '@organisms/dms/toplist/platform-domain-modal.vue'
import BaseDate from '@atoms/fields/base-date.vue'
import AddModal from '@organisms/dms/toplist/add-modal.vue'
import draggable from 'vuedraggable'

export default {
  components: {
    ItemRow,
    BaseDate,
    AddModal,
    AffiliateLinkEdit,
    AffiliateBonusEdit,
    AffiliatePlatformDomainModal,
    ItemPositionModal,
    draggable,
  },
  data: function () {
    return {
      trackerRowEditing: null,
      showAddModal: false,
      showLinkEditModal: false,
      showBonusEditModal: false,

      isLoading: false,
      showFullBonus: false,
      showFullTracker: false,
      showFullAffiliatePlatform: false,
    }
  },
  props: {
    list: {
      type: Object,
      required: true,
    },
    showAffiliatePlatform: {
      type: Boolean,
    },
    storeName: {
      type: String,
      required: true,
    },
    schedulingActive: {
      type: Boolean,
      default: false,
    },
    scheduledList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    notRemovedItems() {
      return this.$store.getters[`${this.storeName}/getItems`].filter((item) => {
        return item.removed === false
      })
    },
    items: {
      get() {
        return this.$store.getters[`${this.storeName}/getItems`]
      },
      set(value) {
        this.$store.commit(`${this.storeName}/setItems`, value)
      },
    },
    realIndexes() {
      let indexes = []
      let count = 1
      this.items.forEach((item, index) => {
        const affiliateOperators = item?.operator?.affiliate_operators || []
        if (
          item.removed === false &&
          (affiliateOperators.length === 0 || affiliateOperators[0]?.status !== 'inactive')
        ) {
          indexes[index] = count
          count++
        }
      })
      return indexes
    },

    label() {
      if (this.schedulingActive) {
        return this.scheduledList ? 'Scheduled' : 'Current'
      }
      return 'Toplist items'
    },
    isPrefilled() {
      return this.list && this.list.list_type === 'prefilled'
    },
    isPrefilledTemplate() {
      return this.list && this.list.list_type === 'prefilled_template'
    },
  },
  watch: {
    schedulingActive(active) {
      if (active === true) {
        this.showFullBonus = false
        this.showFullTracker = false
      }
    },
  },
  methods: {
    showAddModalAction() {
      this.showAddModal = true
      this.$nextTick(() => {
        this.$refs.addModal.show()
      })
    },
    done(items) {
      this.showAddModal = false
      this.$store.dispatch(`${this.storeName}/addItems`, items)
    },
    openPositionModal(item, realIndex) {
      this.$refs.positionModal.show(item, realIndex)
    },
    removeRow(index) {
      this.items.splice(index, 1)
    },
    updateBonus(row, bonus) {
      this.showBonusEditModal = true
      this.$nextTick(() => {
        this.$refs.editBonusModal.show(bonus, row)
      })
    },
    updateTracker(row, id) {
      this.trackerRowEditing = row
      this.showLinkEditModal = true
      this.$nextTick(() => {
        this.$refs.editLinkModal.show({ ids: [id] })
      })
    },
    trackerUpdated(index, result) {
      let clone = cloneDeep(this.trackerRowEditing)
      clone.operator.affiliate_operators[0].links = result
      this.$store.dispatch(`${this.storeName}/updateItem`, { index: index, item: clone })
      this.$emit('link-updated', { index })
    },
    domainUpdated(index, result, row) {
      let clone = cloneDeep(row)
      clone.operator.affiliate_operators[0].links[0].affiliate_platform_domain = result
      this.$store.dispatch(`${this.storeName}/updateItem`, { index: index, item: clone })
    },
    bonusTypeUpdated(index, bonusType) {
      const updatedItem = cloneDeep(this.items[index])
      updatedItem.bonus_name = bonusType.name
      updatedItem.bonus_id = bonusType.id
      this.$store.dispatch(`${this.storeName}/updateItem`, { index: index, item: updatedItem })
    },
    bonusUpdated(result, row) {
      let clone = cloneDeep(row)
      clone.operator.affiliate_operators[0].types = [result]
      this.$store.dispatch(`${this.storeName}/updateItem`, { index: this.index, item: clone })
      this.$emit('bonus-updated', { result })
    },
    copyCurrentToplist() {
      let clone = cloneDeep(this.$store.state['normalToplist'])

      const clonedItems = clone.items.map((item, index) => {
        return {
          ...item,
          id: `copy-${index}-${Date.now()}`,
        }
      })
      this.$store.dispatch(`${this.storeName}/setItems`, clonedItems)
    },

    exportCsv() {
      const filename = `toplist-${moment().format('YYYY-MM-DD-HHmm')}`
      const csvExporter = new ExportToCsv({
        useKeysAsHeaders: true,
        filename: filename,
      })
      let results = this.items.map((item) => {
        return {
          position: item.sort_nr,
          operator: item.operator.name,
          bonus: item.operator?.affiliate_operators[0]?.types[0]?.format || '',
          tracker: item.operator?.affiliate_operators[0]?.links[0]?.link || '',
        }
      })
      csvExporter.generateCsv(results)
    },
    getStoreName() {
      return this.scheduledList ? 'scheduled' : 'normal'
    },
    getDatePickerConfig() {
      return {
        allowInput: true,
        altInput: true,
        altFormat: 'd/m/Y H:i',
        dateFormat: 'Y-m-d H:i:S',
        minDate: new Date(),
        time_24hr: true,
        enableTime: true,
        defaultDate: moment().startOf('day').format(),
      }
    },
  },
}
</script>

<style lang="scss">
.toplist-edit-actions {
  position: absolute;
  top: -50px;
  right: 0;
}
.toplist-edit-warning {
  position: absolute;
  top: -93px;
  right: 0;
  border: 1px solid #f39d1f;

  @media (min-width: 1400px) {
    top: -110px;
  }
}
.toplist-schedule-date-picker input {
  height: 32px;
  width: 190px;
}
.overflow-x-auto {
  overflow-x: auto;
}
</style>
