<template>
  <div class="card">
    <dynamic-form
      ref="form"
      formType="create"
      :formFields="structure?.list?.filter((field) => !field.hidden)"
      :structure="structure"
      :object="object"
      :on-submit="submit"
      :path="config.url"
      :inCard="true"
      :customCreateActionButton="{
        action: submitAndCreateLink,
        title: 'Submit and Create Link',
        icon: 'uil uil-link-add',
        roles: [],
      }"
    />
  </div>
</template>

<script setup>
import { ref, reactive, getCurrentInstance, watch, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import AffiliateOperator from '@models/AffiliateOperator.js'
import AffiliateOperatorsService from '@services/AffiliateOperatorsService.js'

const route = useRoute()
const router = useRouter()
const operatorId = ref(route.params.id)
const object = ref({})
const structure = reactive({ list: AffiliateOperator.getStructure(operatorId.value)?.list })
const config = AffiliateOperator.getConfig()

const form = ref(null)

const { proxy } = getCurrentInstance()

const submit = async (redirect = true) => {
  if (form.value) {
    form.value.isLoading = true
  }

  const submissionData = {
    ...object.value,
    operator_id: operatorId.value,
  }

  let response
  try {
    response = await AffiliateOperatorsService.post(submissionData)

    if (response.data.messages) {
      proxy.showErrorMessages(response.data.messages)
    } else {
      proxy.showSuccessMessage('Sites data created successfully')
      object.value = {}
      if (redirect) {
        router.push(`/operators/${route.params.id}/sites-data/edit/${response.data.result.id}`)
      }
    }
  } catch (error) {
    proxy.showUnknownErrorMessage()
  } finally {
    if (form.value) {
      form.value.isLoading = false
    }
  }

  return response
}

const submitAndCreateLink = async () => {
  const response = await submit(false)

  if (response && response.data && response.data.result && response.data.result.id) {
    const id = response.data.result.id
    router.push(`/operators/${operatorId.value}/sites-data/links/${id}`)
  }
}

watch(
  () => object.value.affiliate_id,
  async (newAffiliateId) => {
    if (newAffiliateId) {
      try {
        const visibilityRules = await getFieldsVisibility(newAffiliateId)
        const list = AffiliateOperator.getStructure(route?.params?.id).list

        // Safely map visibility rules
        const visibilityRuleNames = visibilityRules.map((item) => ({
          fieldName: item.field?.name?.toLowerCase() || '',
          displayName: item.field?.display_name?.toLowerCase() || '',
        }))

        structure.list = list.map((field) => {
          const fieldNameLower = field.name?.toLowerCase() || ''
          const displayNameLower = field.display_name?.toLowerCase() || ''

          // Check if the field should be hidden based on visibility rules
          const isFieldHiddenByRules = visibilityRuleNames.some(
            (rule) => rule.fieldName === fieldNameLower || rule.displayName === displayNameLower
          )

          // Special handling for the 'Logo' field
          const isLogoField =
            field.display_name === 'Logo' &&
            visibilityRuleNames.some((rule) => rule.fieldName === 'logo' || rule.displayName === 'logo')

          // Apply hiding logic
          if (isLogoField || isFieldHiddenByRules) {
            if (field.type === 'list-many') {
              field.hideListMany = true
            } else {
              field.hidden = true
            }
            return field
          }

          // Handle inputs for fields that are not hidden
          if (field.inputs && field.inputs.length > 0) {
            const filteredInputs = field.inputs.filter(
              (inputName) => !visibilityRuleNames.some((rule) => rule.fieldName === inputName.toLowerCase())
            )

            field.inputs = filteredInputs

            if (field.inputs.length === 0) {
              field.hidden = true
            }
          }

          return field
        })
      } catch (error) {
        console.error('Error fetching visible fields:', error)
      }
    }
  },
  { immediate: true }
)

const getFieldsVisibility = async (affiliateId) => {
  try {
    const response = await AffiliateOperatorsService.getVisibleFields(affiliateId, 'Site Data')
    return response.data.result || []
  } catch (error) {
    console.error('Error fetching fields visibility:', error)
    return []
  }
}
</script>
